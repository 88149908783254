import React from 'react';

import Icomoon, { IIcomoonIconProps } from './icomoon';

const DisconnectedRevenueIntegrationIcon = ({
  ...props
}: IIcomoonIconProps) => {
  return <Icomoon { ...props } iconName="revenue_integration" />;
};
export default DisconnectedRevenueIntegrationIcon;
