/* eslint-disable jsx-a11y/no-static-element-interactions */

import React, { useMemo, useCallback, useState } from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';

import { IReportData } from '@src/types/report_service/report_data';
import { useUpdateReportDatas } from '@src/hooks/queries/report_service/report_datas';

import Text from '@src/components/ui/text';
import { reportRefreshState } from '@src/components/ui_v2/filter/atoms';

interface IReportUpdateBannerProps {
  reportDatas: IReportData[],
}

const ReportUpdateBanner = ({
  reportDatas,
}: IReportUpdateBannerProps): JSX.Element => {
  const isReportRefreshing = useRecoilValue(reportRefreshState);
  const { mutate } = useUpdateReportDatas();
  const [update, setUpdate] = useRecoilState(reportRefreshState);
  const [isRefreshing, setIsRefreshing] = useState(false);

  const { isFailed, errMsg, isValidationFailed, validationError, newChangesDetected, newChangesMsg } = useMemo(() => {
    const failedData = reportDatas.filter((reportData) => (
      reportData.updateState
      === window.Docyt.Common.Constants.CUSTOM_REPORT_STATE.UPDATE_STATE_FAILED
      || (reportData.errorMsg && reportData.errorMsg !== '')
    ));
    const failed = failedData.length !== 0;

    const validationFailedDatas = reportDatas.filter((reportData) => (
      reportData.validationResult && reportData.validationResult !== '' && reportData.validationResult !== 'OK'
    ));
    const newChanges = reportDatas.filter((reportData) => (
      reportData.errorMsg && reportData.errorMsg.startsWith('New changes detected ')
    ));
    return {
      isFailed: failed,
      errMsg: failedData[0]?.errorMsg || '',
      isValidationFailed: validationFailedDatas.length > 0,
      validationError: validationFailedDatas[0]?.validationResult || '',
      newChangesDetected: newChanges.length > 0,
      newChangesMsg: newChanges[0]?.errorMsg || ''
    };
  }, [reportDatas]);

  const handleWebhookRefresh = useCallback(() => {
    if (isRefreshing || isReportRefreshing || update) return;
    const reportData = reportDatas[0];
    if (!reportData?.reportId) return;

    setIsRefreshing(true);

    mutate({
      reportId: reportData.reportId,
      startDate: reportData.startDate,
      endDate: reportDatas[reportDatas.length - 1].endDate,
      periodType: reportData.periodType,
      webhook_update: true,
    }, {
      onSuccess: () => {
        setIsRefreshing(false);
        setUpdate(true);
      },
      onError: () => {
        setIsRefreshing(false);
      }
    });
  }, [isRefreshing, isReportRefreshing, update, mutate, reportDatas, setUpdate]);

  const handleLinkClick = useCallback((e: React.MouseEvent) => {
    e.preventDefault();
    e.stopPropagation();
    
    const target = e.target as HTMLElement;
    if (target.tagName === 'A') {
      if ((target as HTMLAnchorElement).getAttribute('href') === '#webhook_update') {
        handleWebhookRefresh();
      }
    }
  }, [handleWebhookRefresh]);

  if (isReportRefreshing) {
    return (
      <div className="report-status-panel m-t-20">
        <Text as="label" className="m-l-10 m-b-0" fontSize={ 14 }>
          { window.Docyt.Common.Constants.Messages.CUSTOM_REPORT_UPDATING_STATE }
        </Text>
      </div>
    );
  }

  if (isFailed) {
    // TODO: We should manage it with error status. Need backend efforts for this.
    if (errMsg === window.Docyt.Common.Constants.Messages.REPORT_CHANGES_DETECTED) {
      return (
        <div className="report-status-panel m-t-20">
          <Text as="label" className="m-l-10 m-b-0">{errMsg}</Text>
        </div>
      );
    }
    return (
      <div className="report-status-panel warning m-t-20">
        <Text as="label" className="m-l-10 m-b-0 font-light in-black-600">
          {/* eslint-disable-next-line react/no-danger */}
          <Text
            as="label"
            className="m-b-0"
            onClick={ handleLinkClick }
            dangerouslySetInnerHTML={ { __html: errMsg } }
          />
        </Text>
      </div>
    );
  }

  if (isValidationFailed || newChangesDetected) {
    return (
      <div className="report-status-panel warning m-t-20">
        <Text as="label" className="m-l-10 m-b-0 font-light in-black-600">
          {/* eslint-disable-next-line react/no-danger */}
          <Text as="label" className="m-b-0" dangerouslySetInnerHTML={ { __html: validationError } } />
          {newChangesDetected && <Text as="label" className="m-b-0" dangerouslySetInnerHTML={ { __html: newChangesMsg } } />}
        </Text>
      </div>
    );
  }

  return (
    <div className="report-status-panel m-t-20">
      <Text as="label" className="m-l-10 m-b-0">This report was generated</Text>
    </div>
  );
};

export default ReportUpdateBanner;
