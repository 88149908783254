import React from 'react';

import { PieChart, Pie, Cell, Label } from 'recharts';

import { getCustomPieChartValues, pieChartConstants } from '../../helper';
import { isSetupStatusComplete } from '../../helper/functional';
import { TSetupStatus } from '../../types';

interface IViewBox {
  cx: number;
  cy: number;
  startAngle?: number;
  endAngle?: number;
  innerRadius?: number;
  outerRadius?: number;
  midAngle?: number;
}

interface IPieChartLabelProps{
viewBox?:IViewBox;
labelText:string;
value:string | number;
}

const PieChartLabel = ({ viewBox, labelText, value }:IPieChartLabelProps) => {
  const { cx, cy } = viewBox || {};
  return (
    <g>
      <text
        alignmentBaseline="middle"
        className="recharts-text recharts-label"
        dominantBaseline="central"
        fontSize="8"
        textAnchor="middle"
        x={ cx }
        y={ cy ? cy - 4 : cy }
      >
        {labelText}
      </text>
      <text
        alignmentBaseline="middle"
        className="recharts-text recharts-label"
        dominantBaseline="central"
        fill="#070C25"
        fontSize="8"
        fontWeight="400"
        textAnchor="middle"
        x={ cx }
        y={ cy ? cy + 2 : cy }
      >
        {value}
      </text>
    </g>
  );
};

interface ICustomPieChartProps {
  percent: number;
  setupStatus?:TSetupStatus;
  colors: string[];
}

const CustomPieChart = ({ percent, setupStatus, colors }: ICustomPieChartProps) => {
  const data = getCustomPieChartValues(percent);
  return (
    <PieChart height={ 36 } width={ 36 }>
      <Pie
        data={ data }
        dataKey="value"
        endAngle={ pieChartConstants.endAngle }
        fill="#8884d8"
        innerRadius={ pieChartConstants.innerRadius }
        outerRadius={ pieChartConstants.outerRadius }
        startAngle={ pieChartConstants.startAngle }
      >
        {data.map((entry, index) => (
          <Cell key={ `cell-${entry.name}` } fill={ isSetupStatusComplete(setupStatus) ? 'grey' : colors[index % colors.length] } />
        ))}
        <Label
          content={ <PieChartLabel labelText="" value={ isSetupStatusComplete(setupStatus) ? 'NA' : `${percent}%` } /> }
          position="center"
        />
      </Pie>
    </PieChart>
  );
};

export default React.memo(CustomPieChart);
