import React from 'react';

import styles from './style.module.scss';

interface TooltipItemProps {
    children?: React.ReactNode;
  }

export const TooltipItem: React.FC<TooltipItemProps> = ({
  children,
}) => (
  <li className={ styles['tooltip-card-item'] }>
    {children}
  </li>
);
