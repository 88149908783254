import React, { FC } from 'react';

import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';

import Form from '@src/components/ui_v2/form';

import { noteValidate, IAcknowledgeFormValues } from './schema';

interface IAcknowledgeFormProps {
  formId: string,
  noteRequired?: boolean,
  onSubmit: (data: IAcknowledgeFormValues) => void;
}

const AcknowledgeForm: FC<IAcknowledgeFormProps> = ({ formId, onSubmit, noteRequired = false }) => {
  const { register, formState: { errors }, handleSubmit } = useForm<IAcknowledgeFormValues>({
    reValidateMode: 'onChange',
    resolver:       yupResolver(noteValidate(noteRequired)),
  });

  return (
    <Form id={ formId } onSubmit={ handleSubmit(onSubmit) }>
      <Form.TextField
        autoComplete="off"
        error={ errors.note?.message }
        label="Note"
        placeholder="Add acknowledgement notes"
        { ...register('note') }
      />
    </Form>
  );
};

export default React.memo(AcknowledgeForm);
