import React, { useEffect } from 'react';

import { yupResolver } from '@hookform/resolvers/yup';
import omit from 'lodash/omit';
import { useController, useForm } from 'react-hook-form';

import { IBusiness } from '@src/types/businesses';

import RevenueReportTypeField from '@src/components/common_v2/form_fields/revenue_report_type_field';
import Form from '@src/components/ui_v2/form';

import {
  IPushIntegrationConnectValues,
  addPushIntegrationConnectValidation,
} from './schema';

interface IPushIntegrationConnectProps {
  business: IBusiness,
  formId: string,
  onSubmit: (data: IPushIntegrationConnectValues) => void;
  onValidationChange: (isValid: boolean) => void;
}

const PushIntegrationConnectForm = ({
  business,
  formId,
  onSubmit,
  onValidationChange,
}: IPushIntegrationConnectProps) => {
  const {
    control,
    formState: { errors, isValid },
    handleSubmit,
    register,
  } = useForm<IPushIntegrationConnectValues>({
    mode:          'onChange',
    resolver:      yupResolver(addPushIntegrationConnectValidation),
    defaultValues: {
      startDate:           '',
      revenueReportTypeId: undefined,
      location:            '',
    },
  });

  const dateControl = useController({ control, name: 'startDate' });
  const rrtControl = useController({ control, name: 'revenueReportTypeId' });

  useEffect(() => {
    onValidationChange(isValid);
  }, [isValid, onValidationChange]);

  return (
    <Form
      id={ formId }
      onSubmit={ handleSubmit(onSubmit) }
    >
      <Form.TextField
        readOnly
        label="Business"
        value={ business.name }
      />
      <Form.DateField
        error={ errors.startDate?.message }
        label="Start Date"
        { ...omit(dateControl.field, 'ref') }
      />
      <RevenueReportTypeField
        businessId={ business.id }
        error={ errors.revenueReportTypeId?.message }
        label="Revenue Mapping"
        { ...omit(rrtControl.field, 'ref') }
      />
      <Form.TextField
        error={ errors.location?.message }
        label="Location"
        maxLength={ 50 }
        { ...register('location') }
      />
    </Form>
  );
};

export default React.memo(PushIntegrationConnectForm);
