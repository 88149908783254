import React from 'react';

import { ISorting } from '@src/types/sorting';

import Table from '@src/components/ui_v2/table';
import { DisconnectedBankAccountIcon, DisconnectedGeneralLedgerIcon, DisconnectedRevenueIntegrationIcon, DuplicateVendorIcon, LedgerConnectionIssueIcon } from '@src/components/utils/icomoon';

import GroupHeader from '../components/custom_cells/group_header';
import { collaborationKeyToStringMap, expenseKeyToStringMap, IBusinessOwnerConfigFormType, reconciliationKeyToStringMap, revenueKeyToStringMap } from '../helper';
import { hasActivePreference, mapObjectKeys } from '../helper/functional';
import { IIconConfig, TDashboardSortColumn } from '../types';

interface IDashboardTableHeadProps {
    preferences?: IBusinessOwnerConfigFormType;
    sorting: ISorting<TDashboardSortColumn>;
}

const DASHBOARD_ICONS: IIconConfig[] = [
  {
    key:       'disconnected-ledger',
    icon:      <DisconnectedGeneralLedgerIcon />,
    hoverText: 'Disconnected General Ledger',
  },
  {
    key:       'disconnected-revenue',
    icon:      <DisconnectedRevenueIntegrationIcon />,
    hoverText: 'Disconnected Revenue Integrations',
  },
  {
    key:       'disconnected-bank',
    icon:      <DisconnectedBankAccountIcon />,
    hoverText: 'Disconnected bank Accounts',
  },
  {
    key:       'ledger-issues',
    icon:      <LedgerConnectionIssueIcon />,
    hoverText: 'General Ledger Connection Issues',
  },
  {
    key:       'duplicate-vendor',
    icon:      <DuplicateVendorIcon />,
    hoverText: 'Duplicate Vendors',
  },
];

const TableHead = ({
  preferences,
  sorting,
}: IDashboardTableHeadProps) => {
  return (
    <Table.Head>
      <Table.Row>
        <Table.HCell<TDashboardSortColumn>
          columnName="business"
          sorting={ sorting }
          width="274px"
        >
          Businesses
        </Table.HCell>
        <Table.HCell width="156px">
          {' '}
          <GroupHeader
            isIssuesHeader
            elements={ DASHBOARD_ICONS }
            heading="Issues"
          />
        </Table.HCell>
        {hasActivePreference('expense', preferences) && (
        <Table.HCell width="300px">
          <GroupHeader
            elements={
               preferences?.expense
               && mapObjectKeys(preferences.expense, expenseKeyToStringMap)
             }
            heading="Expense"
          />
        </Table.HCell>
        )}
        { hasActivePreference('revenue', preferences)
                && (
                  <Table.HCell width="200px">
                    <GroupHeader
                      elements={
                          preferences?.revenue
                          && mapObjectKeys(preferences?.revenue, revenueKeyToStringMap)
                        }
                      heading="Revenue"
                    />
                  </Table.HCell>
                )}
        { hasActivePreference('reconciliation', preferences) && (
        <Table.HCell width="324px">
          <GroupHeader
            elements={
                          preferences?.reconciliation
                          && mapObjectKeys(
                            preferences?.reconciliation,
                            reconciliationKeyToStringMap,
                          )
                        }
            heading="Reconciliation"
          />
        </Table.HCell>
        )}
        {hasActivePreference('collaboration', preferences) && (
        <Table.HCell width="270px">
          {' '}
          <GroupHeader
            elements={
                  preferences?.collaboration
                  && mapObjectKeys(
                    preferences?.collaboration,
                    collaborationKeyToStringMap,
                  )
                }
            heading="Collaboration"
          />
        </Table.HCell>
        )}
      </Table.Row>
    </Table.Head>
  );
};

export default TableHead;
