import React, { useMemo } from 'react';

import { IBillPayProps, IExpenseProps, IExpenseReportsProps } from '@src/types/common_operational_dashboard';

import LineGraph from '@src/components/operational_dashboard/components/custom_cells/line_graph';
import CorporateCardsTooltip from '@src/components/operational_dashboard/components/tooltips/corporate_cards_tooltip';
import ExpenseReportTooltip from '@src/components/operational_dashboard/components/tooltips/expense_report_tooltip';
import { billPayColors,
  corporateCardTooltipData,
  employeeReimbursementsTooltipData,
  expenseReportTooltipData,
  IBusinessOwnerConfigFormType,
  myReimbursementsTooltipData } from '@src/components/operational_dashboard/helper';
import { filterTooltipData, hasActivePreference, isSetupStatusComplete } from '@src/components/operational_dashboard/helper/functional';
import { ICommonProps, TSetupStatus } from '@src/components/operational_dashboard/types';

import HoverTooltipWrapper from './hover_tooltip_wrapper';
import { progressCorporateCardsData } from './utils';

import styles from './style.module.scss';

interface IProcessExpenseReportsDataProps{
  expenseReportsProp: IExpenseReportsProps,
  preferences:IBusinessOwnerConfigFormType,
  setupStatus?:TSetupStatus
}
const ProcessExpenseReportsData =
({ expenseReportsProp, preferences, setupStatus }:IProcessExpenseReportsDataProps) => {
  return (
    <div className={ styles['container-flex'] }>
      {preferences.expense?.expenseReports?.employeeReimbursements && (
      <HoverTooltipWrapper
        content={ (
          <ExpenseReportTooltip
            cellData={ expenseReportsProp?.employeeReimbursements }
            items={ employeeReimbursementsTooltipData }
            title="Employee Reimbursements"
          />
          ) }
      >
        <div className={ styles['box-container'] }>
          <span className={ expenseReportsProp?.employeeReimbursements?.total
            && !(isSetupStatusComplete(setupStatus)) ? styles['table-font-warning'] : styles['table-font'] }
          >
            {isSetupStatusComplete(setupStatus) ? 'NA' : expenseReportsProp.employeeReimbursements?.total ?? '-'}
          </span>
        </div>
      </HoverTooltipWrapper>
      )}
      {preferences.expense?.expenseReports?.myReimbursements && (
      <HoverTooltipWrapper
        content={ (
          <ExpenseReportTooltip
            cellData={ expenseReportsProp?.myReimbursements }
            items={ myReimbursementsTooltipData }
            title="My Reimbursements"
          />
          ) }
      >
        <div className={ styles[expenseReportsProp?.myReimbursements?.total && !(isSetupStatusComplete(setupStatus)) ? 'red-box-container' : 'box-container'] }>
          <span className={ styles['table-font'] }>{isSetupStatusComplete(setupStatus) ? 'NA' : expenseReportsProp?.myReimbursements?.total ?? '-'}</span>
        </div>
      </HoverTooltipWrapper>
      )}
    </div>
  );
};
interface IExpenseCellProps extends IExpenseProps, ICommonProps {
}
const ExpenseCell = (
  { billPay,
    corporateCards,
    expenseReports,
    preferences,
    setupStatus }
: IExpenseCellProps,
) => {
  const progressBillPayData = useMemo(() => {
    if (billPay) {
      return (Object.keys(billPay) as (keyof IBillPayProps)[])
        .filter((key) => preferences?.expense?.billPay?.[key])
        .map((key) => ({
          value: billPay[key] ?? 0,
          color: billPayColors[key],
        }));
    }
    return [];
  }, [billPay, preferences]);

  return (
    <div className={ styles['business-column-container'] }>
      {hasActivePreference('billPay' as keyof IBusinessOwnerConfigFormType, preferences?.expense as IBusinessOwnerConfigFormType) && billPay && (
        <HoverTooltipWrapper
          content={ (
            <ExpenseReportTooltip
              cellData={ billPay }
              items={ filterTooltipData(
                expenseReportTooltipData,
                preferences?.expense?.billPay,
              ) }
            />
          ) }
        >
          <LineGraph
            segments={ preferences ? progressBillPayData : [] }
            setupStatus={ setupStatus }
          />
        </HoverTooltipWrapper>
      )}
      {preferences?.expense?.corporateCards?.unapprovedReceipts && (
        <HoverTooltipWrapper
          content={ (
            <CorporateCardsTooltip
              isArray
              cellData={ corporateCards }
              item={ corporateCardTooltipData }
            />
          ) }
        >
          <LineGraph
            segments={ progressCorporateCardsData(corporateCards) }
            setupStatus={ setupStatus }
          />
        </HoverTooltipWrapper>
      )}
      {expenseReports && preferences && hasActivePreference('expenseReports', preferences.expense as IBusinessOwnerConfigFormType) && (
      <ProcessExpenseReportsData
        expenseReportsProp={ expenseReports }
        preferences={ preferences }
        setupStatus={ setupStatus }
      />
      )}
    </div>
  );
};
export default ExpenseCell;
