import React, { useCallback, useState } from 'react';

import toastr from '@lib/toastr';
import { useBusinessContext } from '@src/hooks/contexts/business_context';
import { IUseModalProps, makeUseModal } from '@src/hooks/modal';
import { useUpsertIntegration, useSetIntegrationPaused } from '@src/hooks/queries/integrations/integrations';
import { IUpsertIntegrationResponse } from '@src/requests/integrations/integrations';
import { TID } from '@src/types/common';

import Modal from '@src/components/ui/modal';
import Button from '@src/components/ui_v2/buttons/button';

import Form from './form';
import { usePushIntegrationSuccessModel } from './push_integration_success_model';
import { IPushIntegrationConnectValues } from './schema';

interface IPushIntegrationConnectModelProps extends IUseModalProps {
  externalSystemId: TID,
  externalSystemName: string
}

const PushIntegrationConnectModel = ({
  isOpen,
  onDone,
  onCancel,
  externalSystemId,
  externalSystemName,
  ...props
}: IPushIntegrationConnectModelProps) => {
  const [integration, setIntegration] = useState<IUpsertIntegrationResponse | null>(null);
  const [isFormValid, setIsFormValid] = useState(false);
  const pushIntegrationSuccessModel = usePushIntegrationSuccessModel();
  const business = useBusinessContext();

  const upsert = useUpsertIntegration();
  const { mutate } = upsert;

  const setAsActive = useSetIntegrationPaused();
  const { mutate: setAsActiveMutate } = setAsActive;

  const handleError = useCallback((response) => {
    toastr.error(response?.response?.data?.errors[0], 'Error');
  }, []);

  const connectPushSystem = useCallback((data: IPushIntegrationConnectValues) => {
    mutate({
      businessId:  business.id,
      integration: {
        externalSystemId,
        revenueReportTypeId:  data.revenueReportTypeId,
        startDate:            data.startDate,
        location:             data.location,
        externalSystemConfig: {},
      },
      actionType: 'create',
    }, {
      onSuccess: (response) => {
        if (response.emailAddress) {
          setIntegration(response);
          pushIntegrationSuccessModel.open();
          onDone();
          setAsActiveMutate({ integrationId: response.id, isPaused: false });
        }
      },
      onError: handleError,
    });
  }, [externalSystemId, handleError, business.id, mutate, pushIntegrationSuccessModel, onDone, setAsActiveMutate]);

  return (
    <>
      <pushIntegrationSuccessModel.Component
        UpsertIntegrationResponse={ integration }
        { ...pushIntegrationSuccessModel.props }
      />
      <Modal
        show={ isOpen }
        title={ `${externalSystemName} Revenue System` }
        { ...props }
      >
        <>
          <Modal.Body>
            <p>Select the starting date for the revenue system.</p>
            <Form
              business={ business }
              formId="edit_linked_businesses_form"
              onSubmit={ connectPushSystem }
              onValidationChange={ setIsFormValid }
            />
          </Modal.Body>
          <Modal.Footer className="modal-footer-v2">
            <Button
              className="btn-outline"
              variant="link"
              onClick={ onCancel }
            >
              Back
            </Button>
            <Button
              disabled={ !isFormValid }
              form="edit_linked_businesses_form"
              type="submit"
              variant="primary"
            >
              Connect
            </Button>
          </Modal.Footer>
        </>
      </Modal>
    </>
  );
};

const usePushIntegrationConnectModel = makeUseModal(PushIntegrationConnectModel);

export {
  usePushIntegrationConnectModel,
  PushIntegrationConnectModel as default,
};
