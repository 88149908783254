import React, { useCallback } from 'react';

import { yupResolver } from '@hookform/resolvers/yup';
import { SubmitHandler, useForm } from 'react-hook-form';

import { IManagementGroup } from '@src/types/management_groups';

import {
  IManagementGroupInput,
  managementGroupValidation,
} from '@src/components/management_groups/modals/schema';
import Form from '@src/components/ui/form/form';
import { SwitchInput } from '@src/components/ui_v2/inputs';
import Tooltip from '@src/components/ui_v2/tooltip';
import { UnverifiedIcon } from '@src/components/utils/icomoon';

import styles from '../styles.module.scss';

interface IManagementGroupModalProps {
  managementGroup?: IManagementGroup,
  formId?: string,
  onSubmit: SubmitHandler<IManagementGroupInput>,
}

const EditManagementGroupModalForm = ({
  managementGroup,
  formId,
  onSubmit,
}: IManagementGroupModalProps) => {
  const {
    register,
    setValue,
    handleSubmit,
    formState: { errors },
  } = useForm<IManagementGroupInput>({
    defaultValues: {
      name: managementGroup?.name,
      intercompanyEnabled: managementGroup?.intercompanyEnabled,
    },
    resolver: yupResolver(managementGroupValidation),
  });

  const handleChange = useCallback((isChecked: boolean) => {
    setValue('intercompanyEnabled', isChecked);
  }, [setValue]);

  return (
    <Form id={ formId } onSubmit={ handleSubmit(onSubmit) }>
      <div className={ styles['management-group-form'] }>
        <Form.TextField
          { ...register('name') }
          autoFocus
          autoComplete="off"
          error={ errors.name?.message }
          label="Management Group Name"
          maxLength={ 80 }
          placeholder="Enter Management Group Name"
        />
        <div className="form-field">
          <div className="form-field-item form-field-name">
            <div className="display-flex items-center">
              <span>Inter-company Settlements</span>
              <Tooltip.Hover
                content="Inter-company settlements allow businesses to settle financial transactions among themselves within a management group."
              >
                <UnverifiedIcon className="pointer" fontSize={ 14 } ml={ 4 } />
              </Tooltip.Hover>
            </div>
          </div>
          <div className="form-field-item form-field-value">
            <SwitchInput
              { ...register('intercompanyEnabled') }
              title={ managementGroup?.intercompanyEnabled ? 'Enabled' : 'Disabled' }
              onChange={ handleChange }
            />
          </div>
        </div>
      </div>
    </Form>
  );
};

export {
  IManagementGroupModalProps,
  EditManagementGroupModalForm as default,
};
