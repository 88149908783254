import React from 'react';

import styles from './style.module.scss';

interface ITooltipWrapperProps {
  title?: string;
  children: React.ReactNode;
}

const TooltipWrapper = ({ title, children }: ITooltipWrapperProps) => {
  return (
    <div className={ styles['tooltip-card-container'] }>
      {title && <div className={ styles['tooltip-card-title'] }>{title}</div>}
      <ul className={ styles['tooltip-card-list'] }>
        {children}
      </ul>
    </div>
  );
};

export default TooltipWrapper;
