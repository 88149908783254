import React, { useCallback, useEffect, useMemo } from 'react';

import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import { useSearchParams } from 'react-router-dom';

import toastr from '@lib/toastr';
import {
  useUpdateOperationDashboardPreferences,
  useGetOperationDashboardPreferences,
} from '@src/hooks/queries/operational_dashboard/operation_dashboard_service';

import CheckboxWithDescription from '@src/components/operational_dashboard/config_form/checkbox_with_description';
import Modal from '@src/components/operational_dashboard/config_form/Modal';
import {
  filterMapper,
  businessOwnerFilterValidation,
  IBusinessOwnerConfigFormType,
  defaultView,
} from '@src/components/operational_dashboard/helper';
import Form from '@src/components/ui_v2/form';

import { TViewOperationalDashboard } from '../types';

import styles from './style.module.scss';

interface IConfigFormProps {
  open: boolean;
  handleHide: () => void;
}

const createName = ({
  heading,
  subHeading,
}: {
  heading: string;
  subHeading?: string;
}) => {
  if (heading && subHeading) {
    return `${heading}.${subHeading}`;
  }
  return heading;
};

const ConfigForm = ({ open, handleHide }: IConfigFormProps) => {
  const [searchParams] = useSearchParams();
  const queryView = (searchParams.get('view') || defaultView) as TViewOperationalDashboard;
  const { data: preferences } = useGetOperationDashboardPreferences(
    { view: queryView },
  );
  const { handleSubmit, setValue, control } = useForm({
    resolver:      yupResolver(businessOwnerFilterValidation),
    defaultValues: preferences ?? {},
  });

  const memoizedFilterMapper = useMemo(() => filterMapper(queryView ?? ''), [queryView]);
  const updateForm = useUpdateOperationDashboardPreferences();

  const applyPreferencesToFilters = useCallback(
    ({ reset = false }: { reset?: boolean }) => {
      memoizedFilterMapper?.forEach((section) => {
        section.child.forEach((subSection) => {
          subSection.elements.forEach((element) => {
            const sectionPreference =
              preferences?.[
                section.headingKey as keyof IBusinessOwnerConfigFormType
              ];
            let preferenceValue;
            if (sectionPreference && subSection.headingKey) {
              const subSectionPreference =
                sectionPreference[
                  subSection.headingKey as keyof typeof sectionPreference
                ];
              if (subSectionPreference) {
                preferenceValue =
                  subSectionPreference[
                    element.name as keyof typeof subSectionPreference
                  ];
              }
            } else if (sectionPreference) {
              preferenceValue =
                sectionPreference[
                  element.name as keyof typeof sectionPreference
                ];
            }
            if (preferenceValue !== undefined) {
              setValue(
                `${createName({
                  heading:    section.headingKey,
                  subHeading: subSection.headingKey,
                })}.${element.name}` as any,
                reset ? false : preferenceValue,
              );
            }
          });
        });
      });
    },
    [preferences, memoizedFilterMapper, setValue],
  );

  useEffect(() => {
    if (preferences) {
      applyPreferencesToFilters({});
    }
  }, [applyPreferencesToFilters, preferences]);

  const handleReset = useCallback(() => {
    if (preferences) {
      applyPreferencesToFilters({ reset: true });
    }
  }, [preferences, applyPreferencesToFilters]);

  const submitHandler = useCallback((bodyData:IBusinessOwnerConfigFormType) => {
    updateForm.mutate(
      { preference: { ...bodyData } },
      {
        onSuccess: () => {
          toastr.success(
            'Preferences Updated Successfully',
            'Preferences Update',
          );
          handleHide();
        },
        onError: (error) => {
          toastr.error(
            error.message,
            'Error Updating Preferences',
          );
        },
      },
    );
  }, [handleHide, updateForm]);

  if (!open) return null;

  return (
    <Form onSubmit={ handleSubmit(submitHandler) }>
      <Modal
        handleHide={ handleHide }
        handleReset={ handleReset }
        open={ open }
      >
        <div className={ styles['config-form-container'] }>
          {memoizedFilterMapper?.map((section) => (
            <div
              key={ section.heading }
              className={ styles['filter-content-section'] }
            >
              <h2>{section.heading}</h2>
              <div className={ styles['filter-content-inner'] }>
                {section.child.map((subSection) => (
                  <div
                    key={ subSection.heading }
                    className={ styles['config-form-subsection'] }
                  >
                    <h3>{subSection.heading}</h3>
                    {subSection.elements.map((element) => (
                      <div key={ element.name } className={ styles.content }>
                        <CheckboxWithDescription
                          control={ control }
                          description={ element.description }
                          label={ element.title }
                          name={ `${createName({
                            heading:    section.headingKey,
                            subHeading: subSection.headingKey,
                          })}.${element.name}` }
                        />
                      </div>
                    ))}
                  </div>
                ))}
              </div>
            </div>
          ))}
        </div>
      </Modal>
    </Form>
  );
};
export default React.memo(ConfigForm);
