import { omit } from 'lodash';

import { TDate, TMongoID } from '@src/types/common';
import { TReportDataPeriodType } from '@src/types/report_service/report';
import { IItemValue, IReportData } from '@src/types/report_service/report_data';
import { IItemAccount } from '@src/types/report_service/report_item';
import { camelizeKeys, underscoreKeys } from '@src/utils/transform_keys';

import { apiDelete, apiGet, apiPost } from '../helpers';

interface IGetReportDatasParams {
  reportId: TMongoID,
  from?: TDate,
  to?: TDate,
  includeTotal?: boolean,
  current?: TDate,
  isDaily?: boolean,
  data_type?: TMongoID,
  filter_applied?: TMongoID,
  periodType?: TReportDataPeriodType
}

interface IUpdateReportDatasParams {
  reportId: TMongoID,
  startDate: TDate,
  endDate: TDate,
  periodType: TReportDataPeriodType,
  latestUpdatedAt?: TDate,
  webhook_update?: boolean,
}

export interface ILinkBusinessLaborReportsDepartment {
  reportId: TMongoID,
  itemId: TMongoID,
  dimensionLabels: string[],
  dimensionCode: TMongoID,
}

const getReportDatas = (params: IGetReportDatasParams, endPoint: string): Promise<IReportData[]> => {
  return apiGet(
    `/${endPoint}/api/v1/reports/${params.reportId}/report_datas/by_range`,
    underscoreKeys(omit(params, 'reportId')),
  ).then((data) => camelizeKeys(data.report_datas) as IReportData[]);
};

const getDepartmentDatas = (params: IGetReportDatasParams): Promise<IReportData[]> => {
  return apiGet(
    `/reports/api/v1/reports/${params.reportId}/department_report_datas/by_range`,
    underscoreKeys(omit(params, 'reportId')),
  ).then((data) => camelizeKeys(data.report_datas) as IReportData[]);
};

const updateReportDatas = (params: IUpdateReportDatasParams): Promise<null> => {
  return apiPost(
    `/reports/api/v1/reports/${params.reportId}/report_datas/update_data`,
    underscoreKeys(params),
  ).then();
};

const getReportIdentifierItemAccountValues = (reportId: TMongoID, params: any): Promise<IItemAccount[]> => {
  return apiGet(
    `/reports/api/v1/reports/${reportId}/item_account_values/by_range`,
    params,
  ).then((data) => camelizeKeys(data.item_account_values) as IItemAccount[]);
};

const getReportIdentifierItemValues = (reportId: TMongoID, params: any): Promise<IItemValue[]> => {
  return apiGet(
    `/reports/api/v1/reports/${reportId}/item_values/by_range`,
    params,
  ).then((data) => camelizeKeys(data.item_values) as IItemValue[]);
};

const getBusinessLaborReportDepartment = (
  params: { businessId: number, dimension_code: string },
): Promise<{ label: string }[]> => {
  return apiGet(
    `/reports/api/v1/dimensions?business_id=${params.businessId}&dimension_code=${params.dimension_code}`,
  ).then((data) => camelizeKeys(data.dimensions) as { label: string }[]);
};

const getBusinessLaborReportMappedByDepartment = (params: {reportId: string, itemId: string}): Promise<{
  dimensionCode: string,
  dimensionLabel: string,
}[]> => {
  return apiGet(
    `/reports/api/v1/reports/${params.reportId}/item_dimensions?item_id=${params.itemId}`,
  ).then((data) => camelizeKeys(data.item_dimensions) as {
    dimensionCode: string,
    dimensionLabel: string,
  }[]);
};

const setLinkBusinessLaborReportsDepartment = (params: ILinkBusinessLaborReportsDepartment): Promise<null> => {
  return apiPost(
    `/reports/api/v1/reports/${params.reportId}/item_dimensions/upsert`,
    underscoreKeys(params),
  ).then();
};

const setUnlinkBusinessLaborReportsDepartment = (
  params: { reportId: string, itemId: string, dimensionLabel: string },
): Promise<null> => {
  return apiDelete(
    `/reports/api/v1/reports/${params.reportId}/item_dimensions/unlink`,
    underscoreKeys({ itemId: params.itemId, dimensionLabel: params.dimensionLabel }),
  ).then();
};

export {
  IGetReportDatasParams,
  IUpdateReportDatasParams,
  getReportDatas,
  getDepartmentDatas,
  updateReportDatas,
  getReportIdentifierItemAccountValues,
  getReportIdentifierItemValues,
  getBusinessLaborReportMappedByDepartment,
  setLinkBusinessLaborReportsDepartment,
  setUnlinkBusinessLaborReportsDepartment,
  getBusinessLaborReportDepartment,
};
