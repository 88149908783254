import React, { memo, useCallback } from 'react';

import { NumberBoxValue, TSetupStatus } from '@src/components/operational_dashboard/types';

import { isSetupStatusComplete } from '../../helper/functional';

import styles from './style.module.scss';

interface INumCommonProps {
  value?: NumberBoxValue;
  isWarning?: boolean;
}

interface INumberInBoxProps extends INumCommonProps {
  setupStatus?: TSetupStatus;
  isDanger?: boolean;
}

interface IValueSpanProps extends INumCommonProps {}

const ValueSpan = ({ value, isWarning }: IValueSpanProps) => {
  if (!value || value.type === 'empty') {
    return <span className={ styles.line }>-</span>;
  }

  if (value.type === 'indicator') {
    return value.value ? (
      <span className={ styles['dot-red'] } />
    ) : null;
  }

  if (value.type === 'number') {
    return value.value ? (
      <span className={ isWarning ? styles['table-font-warning'] : '' }>
        {value.value}
      </span>
    )
      : <span className={ styles.line }><strong>-</strong></span>;
  }

  return null;
};

const NumberBox = ({
  value,
  setupStatus,
  isDanger = false,
  isWarning = false,
}: INumberInBoxProps) => {
  const getBoxContainerClass = useCallback(() => {
    if (isDanger && value?.type === 'number' && value.value) {
      return 'red-box-container';
    }
    if (value?.type === 'indicator' && value?.value === false) {
      return 'empty-box-container';
    }
    return 'box-container';
  }, [isDanger, value]);

  if (!value || value.type === 'empty') {
    return <div className={ styles['empty-box-container'] } />;
  }

  return (
    <div
      className={
        styles[
          getBoxContainerClass()
        ]
      }
    >
      {setupStatus ? (
        <span className={ styles['table-font'] }>
          {isSetupStatusComplete(setupStatus) ? 'NA' : value.value ?? '-'}
        </span>
      ) : (
        <span className={ styles['table-font'] }>
          <ValueSpan isWarning={ isWarning } value={ value } />
        </span>
      )}
    </div>
  );
};

export default memo(NumberBox);
