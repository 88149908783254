import React, { FC } from 'react';

import { UseInfiniteQueryResult } from 'react-query';

import { IGetBankAccountReconciliationDocumentsResponse } from '@src/requests/bank_account_reconciliation_documents';
import { IBankAccountReconciliationDocument, TIBankAccountReconciliationDocumentSortColumn } from '@src/types/bank_account_reconciliation_document';
import { ISorting } from '@src/types/sorting';

import { RECONCILIATION_CENTER_DRAWER_TABLE_HEIGHT } from '@src/components/reconciliation_center/month_end/utils';
import { AppLink } from '@src/components/ui_v2/buttons';
import CollectionTable from '@src/components/ui_v2/collection_table';
import { MoreInfoIcon } from '@src/components/utils/icomoon';

import Filter from './filter';

interface TableProps {
  infiniteQuery: UseInfiniteQueryResult<IGetBankAccountReconciliationDocumentsResponse, Error>,
  records: IBankAccountReconciliationDocument[],
  sorting: ISorting<TIBankAccountReconciliationDocumentSortColumn>,
  showSelect: boolean,
  handleCheckboxClick?: (id: unknown) => void,
  rowSelectable: boolean
}

const detailLink = (document: IBankAccountReconciliationDocument) => {
  if (document.docytId) {
    return (
      <AppLink newWindow href={ `/document/${document.docytId}` }>
        <MoreInfoIcon fontSize={ 16 } inColor="black" />
      </AppLink>
    );
  }

  return '';
};

const Table: FC<TableProps> =
  ({ records, sorting, infiniteQuery, showSelect, handleCheckboxClick, rowSelectable }) => {
    return (
      <CollectionTable<
        IBankAccountReconciliationDocument,
        TIBankAccountReconciliationDocumentSortColumn>
        isRegionScroll
        filter={ <Filter /> }
        height="calc(80vh - 360px)"
        isRowSelectable={ () => rowSelectable }
        query={ infiniteQuery }
        records={ records }
        showSelect={ showSelect }
        sorting={ sorting }
        onCheckboxClick={ handleCheckboxClick }
      >
        <CollectionTable.DateColumn<
            IBankAccountReconciliationDocument,
            TIBankAccountReconciliationDocumentSortColumn
          >
          name="transaction_date"
          sortColumn="transaction_date"
          title="Date"
          value={ (document) => document.transactionDate }
        />

        <CollectionTable.TextColumn<
          IBankAccountReconciliationDocument,
          TIBankAccountReconciliationDocumentSortColumn
        >
          name="memo"
          sortColumn="memo"
          title="Description"
          value={ (document) => document.memo }
          width="110px"
        />

        <CollectionTable.TextColumn<
            IBankAccountReconciliationDocument,
            TIBankAccountReconciliationDocumentSortColumn
          >
          name="name"
          sortColumn="name"
          title="Name"
          value={ (document) => document.name }
        />

        <CollectionTable.AmountColumn<
            IBankAccountReconciliationDocument,
            TIBankAccountReconciliationDocumentSortColumn
          >
          name="amount"
          sortColumn="amount"
          title="Amount"
          value={ (document) => document.amount }
          width="120px"
        />

        <CollectionTable.IconColumn<IBankAccountReconciliationDocument>
          name="detail_link"
          title="Detail Link"
          value={ detailLink }
        />
      </CollectionTable>
    );
  };

export default Table;
