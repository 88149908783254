import React, { memo } from 'react';

import { TooltipItem } from './tooltip_item';
import TooltipWrapper from './wrapper';

import styles from './style.module.scss';

interface ListItem {
  id: string;
  iconColor?: string;
  heading: string;
  amount?: number;
  subItems?: ListItem[];
  actionLabel?: string;
  actionLink?: string;
  icon?: string;
  date?: string;
}
interface IssueCellTooltipProps {
  title?: string;
  items: ListItem[];
  value?: string | number;
}
const IssueCellTooltip: React.FC<IssueCellTooltipProps> = ({
  title,
  items,
  value,
}) => {
  return (
    <TooltipWrapper title={ title }>
      {items.map((item) => (
        <TooltipItem key={ item.id }>
          <div className={ styles['tooltip-card-item-main'] }>
            {item.iconColor && (
            <span
              className={ styles['tooltip-card-line'] }
              style={ { backgroundColor: item.iconColor } }
            />
            )}
            {item.icon && (
            <img
              alt={ item?.heading }
              className={ styles['tooltip-card-icon'] }
              src={ item.icon }
            />
            )}
            {value && <span>{value}</span>}
            <span className={ styles['tooltip-card-heading'] }>
              {item.heading}
              {' '}
              {item.amount !== undefined && `- $${item.amount}`}
              {item.date && (
              <div className={ styles['tooltip-card-text'] }>
                Since
                {item.date}
              </div>
              )}
            </span>
            {item.actionLink && item.actionLabel && (
            <a
              className={ styles['tooltip-card-action-link'] }
              href={ item.actionLink }
            >
              {item.actionLabel}
            </a>
            )}
          </div>
          {item.subItems && (
          <ul className={ styles['tooltip-card-sub-list'] }>
            {item.subItems.map((subItem) => (
              <li
                key={ subItem.id }
                className={ styles['tooltip-card-sub-item'] }
              >
                <span className={ styles['tooltip-card-sub-heading'] }>
                  {subItem.heading}
                  {' '}
                  {subItem.amount !== undefined && `- $${subItem.amount}`}
                </span>
              </li>
            ))}
          </ul>
          )}
        </TooltipItem>
      ))}
    </TooltipWrapper>
  );
};
export default memo(IssueCellTooltip);
