import React, { memo, useState } from 'react';

import Root from '@src/components/root';
import { OdConfigIcon } from '@src/components/utils/icomoon/';

import ConfigForm from './config_form';
import OperationsDashboardTableList from './table/list';

import styles from './style.module.scss';

const DashboardMainView = () => {
  const [open, setOpen] = useState<boolean>(false);
  const handleShow = () => setOpen(true);
  const handleHide = () => setOpen(false);
  return (
    <Root>
      <div className={ styles['filter-top-container'] }>
        <OdConfigIcon
          className={ styles['od-config-icon'] }
          fontSize={ 20 }
          onClick={ handleShow }
        />
      </div>
      <ConfigForm handleHide={ handleHide } open={ open } />
      <OperationsDashboardTableList />
    </Root>
  );
};
export default memo(DashboardMainView);
