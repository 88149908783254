import React, { FC } from 'react';

import Table from '@src/components/reconciliation_center/journal_entries/header/table';

import useJournalDataHandling from './use_journal_data_handling';

interface AddFormProps {
  close: () => void;
}

const AddForm: FC<AddFormProps> = ({ close }) => {
  const { additionalParams, onSuccess, balanceSheetAccount, dateRange } = useJournalDataHandling();
  const defaultRows = balanceSheetAccount ? [
    {
      account:         balanceSheetAccount.id,
      accountObj:      balanceSheetAccount,
      type:            balanceSheetAccount.accType === 'Accounts Payable' ? 'Vendor' : 'QuickbooksCustomer',
      hasAccountValue: true,
    }, {}]
    : undefined;

  return (
    <Table
      additionalParams={ additionalParams }
      close={ close }
      dateRange={ dateRange }
      defaultRows={ defaultRows }
      onSuccess={ onSuccess }
    />
  );
};

export default AddForm;
