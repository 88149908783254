/* eslint-disable max-len */
import React from 'react';

import texts from '@src/constants/month_end_reconciliation_items';
import { useBankStatementContext } from '@src/hooks/contexts/bank_statement_context';
import { IBankAccountReconciliationItem } from '@src/types/bank_account_reconciliations';

import { AppLink } from '@src/components/ui_v2/buttons';
import Table from '@src/components/ui_v2/table';
import { MoreInfoIcon } from '@src/components/utils/icomoon';

import { amountFormatterX } from './utils';
import WithSecondLevelRows from './with_second_level_rows';

import styles from './styles.module.scss';

interface RowProps {
  item: IBankAccountReconciliationItem,
  linkTo?: string | JSX.Element,
  hasSecondLevelRows?: boolean,
}

const MonthEndRow = ({
  item,
  linkTo,
  hasSecondLevelRows = false,
}: RowProps) => {
  const bankStatement = useBankStatementContext();
  let bankStatementState = null;
  let itemCount = null;
  let showLink = true;
  let stateText = null;

  if (hasSecondLevelRows) {
    let stateClassName = null;
    if (bankStatement?.state === 'verified') {
      stateClassName = 'state-verified';
      stateText = 'Verified';
    } else if (bankStatement?.state === 'not_available') {
      stateClassName = 'state-no-statement-available';
      stateText = 'No Statement Available';
      showLink = false;
    } else {
      stateClassName = 'state-unverified';
      stateText = 'Unverified';
    }
    bankStatementState = (
      <Table.TextCell hideValueTooltip className={ styles[stateClassName] }>
        { stateText }
      </Table.TextCell>
    );
  } else if (item.count === 0) {
    itemCount = (
      <Table.TextCell hideValueTooltip className={ styles['amount-zero'] }>{amountFormatterX(0.00)}</Table.TextCell>
    );
  } else {
    itemCount = (
      <Table.TextCell hideValueTooltip className={ styles.amount }>
        { item.count }
        { ' ' }
        (
        { amountFormatterX(item.amount) }
        )
      </Table.TextCell>
    );
  }

  let itemIcon;

  if (typeof linkTo === 'object') {
    itemIcon = linkTo;
  } else if (typeof linkTo === 'string') {
    itemIcon = <AppLink newWindow href={ linkTo }><MoreInfoIcon fontSize={ 16 } /></AppLink>;
  }
  const { title, tooltip } = texts[item.itemType];

  const firstRow = (
    <Table.Row className={ styles.row }>
      <Table.TextCell className={ hasSecondLevelRows && bankStatement?.state === 'verified' ? styles['row-arrow-down'] : '' } tooltip={ tooltip }>
        { title }
      </Table.TextCell>

      { bankStatementState || itemCount }

      <Table.IconCell>
        { ((bankStatementState
        && showLink) || itemCount)
        && itemIcon }
      </Table.IconCell>
      <Table.DateCell date={ item.acknowledgedOn } emptyValue="—" />
      <Table.UserCell emptyValue="—" user={ item.acknowledgedBy } />
    </Table.Row>
  );

  if (hasSecondLevelRows && bankStatement?.state === 'verified') {
    return <WithSecondLevelRows bankStatement={ bankStatement }>{firstRow}</WithSecondLevelRows>;
  }

  return firstRow;
};

export default React.memo(MonthEndRow);
