import React from 'react';

import { useController } from 'react-hook-form';

import { CheckboxInput } from '@src/components/ui_v2/inputs';

import styles from './style.module.scss';

interface ICheckboxInputProps {
  description: string;
  label: string;
  name: string;
  control: any;
}
const CheckboxWithDescription = ({
  description,
  label,
  name,
  control,
}: ICheckboxInputProps) => {
  const { field } = useController({ name, control });
  const { onChange, value } = field;
  const handleCheckboxChange = (e: boolean) => {
    onChange(e);
  };
  return (
    <>
      <CheckboxInput
        checked={ value ?? false }
        title={ label }
        { ...field }
        onChange={ handleCheckboxChange }
      />
      <div className={ styles.description }>
        <p>{description}</p>
      </div>
    </>
  );
};
export default CheckboxWithDescription;
