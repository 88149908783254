import React, { memo } from 'react';

import { ICommonTooltipProps } from '@src/components/operational_dashboard/types';
import { AppLink } from '@src/components/ui_v2/buttons';
import ImgIcon from '@src/components/utils/img_icons/img_icon';

import { TooltipItem } from './tooltip_item';
import TooltipWrapper from './wrapper';

import styles from './style.module.scss';

const UndepositedRevenueTooltip: React.FC<ICommonTooltipProps> = ({
  title,
  items,
  cellData,
}) => {
  return (
    <TooltipWrapper title={ title }>
      {items?.map((item) => (
        <TooltipItem key={ item.id }>
          <div className={ styles['tooltip-card-item-main'] }>
            {item.key && <span>{cellData?.[item.key] ?? null}</span>}
            {item.iconColor && (
            <span
              className={ styles['tooltip-card-line'] }
              style={ { backgroundColor: item.iconColor } }
            />
            )}
            {item.icon && (
            <ImgIcon
              alt={ item?.heading }
              className={ styles['tooltip-card-icon'] }
              src={ item.icon }
            />
            )}
            {item?.value && <span>{item?.value ?? 0}</span>}
            <span className={ styles['tooltip-card-heading'] }>
              {item.heading}
              {' '}
              {item.amount !== undefined && `- $${item.amount ?? 0}`}
            </span>
            {item.actionLink && item.actionLabel && (
            <AppLink
              className={ styles['tooltip-card-action-link'] }
              href={ item.actionLink }
            >
              {item.actionLabel}
            </AppLink>
            )}
          </div>
          {item.subItems && (
          <ul className={ styles['tooltip-card-sub-list'] }>
            {item.subItems.map((subItem) => (
              <li
                key={ subItem.id }
                className={ styles['tooltip-card-sub-item'] }
              >
                <span className={ styles['tooltip-card-sub-heading'] }>
                  {subItem.heading}
                  {' '}
                  {subItem.amount !== undefined
                        && `- $${subItem.amount ?? 0}`}
                </span>
              </li>
            ))}
          </ul>
          )}
        </TooltipItem>
      ))}
    </TooltipWrapper>
  );
};
export default memo(UndepositedRevenueTooltip);
