import React from 'react';

import { UseInfiniteQueryResult } from 'react-query';
import { useSearchParams } from 'react-router-dom';

import { useGetOperationDashboardPreferences }
  from '@src/hooks/queries/operational_dashboard/operation_dashboard_service';
import { IGetDashboardTableServiceResponse } from '@src/requests/operational_dashboard/operation_dashboard_service';
import { ISorting } from '@src/types/sorting';

import TableBody from '@src/components/operational_dashboard/table/table_body';
import TableHead from '@src/components/operational_dashboard/table/table_head';
import { IDashboardData, TDashboardSortColumn,
  TViewOperationalDashboard } from '@src/components/operational_dashboard/types';
import Table from '@src/components/ui_v2/table';

import { defaultView } from '../helper';

import style from './style.module.scss';

interface IDashboardTableProps {
  infiniteQuery: UseInfiniteQueryResult<
    IGetDashboardTableServiceResponse,
    Error
  >;
  records: IDashboardData[];
  sorting: ISorting<TDashboardSortColumn>;
}

const DashboardTable = ({
  infiniteQuery,
  records,
  sorting,
}: IDashboardTableProps) => {
  const [searchParams] = useSearchParams();
  const view = (searchParams.get('view') || defaultView) as TViewOperationalDashboard;
  const { data: preferences } = useGetOperationDashboardPreferences(
    { view },
  );

  return (
    <Table query={ infiniteQuery } wrapperClassName={ style['table-container'] }>
      <TableHead preferences={ preferences } sorting={ sorting } />
      <TableBody preferences={ preferences } records={ records } view={ view } />
    </Table>
  );
};

export default DashboardTable;
