import React from 'react';

import { useDashboardTableCollection } from '@src/components/operational_dashboard/hooks';
import Table from '@src/components/operational_dashboard/table/table';
import TableSection from '@src/components/ui_v2/table_section';
import Section from '@src/components/utils_v2/section';

import styles from './style.module.scss';

const OperationsDashboardTableList = () => {
  const collection = useDashboardTableCollection();

  return (
    <Section.Provider section={ collection?.section }>
      <TableSection className={ styles['operational-dashboard-table-section'] }>
        <Table
          infiniteQuery={ collection.query }
          records={ collection.records }
          sorting={ collection.sorting }
        />
      </TableSection>
    </Section.Provider>
  );
};

export default React.memo(OperationsDashboardTableList);