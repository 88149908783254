import React, { useMemo } from 'react';

import { UseInfiniteQueryResult } from 'react-query';

import { rcMonthEndDocmentsOtherDocumentsPath, rcMonthEndDocmentsOtherDocumentsStatementsPath } from '@src/routes';
import { IStatementState, TStatementState, IBalanceSheetStatement, TStatementDetailsSortColumn } from '@src/types/balance_sheet_statements';
import { TID, TYear } from '@src/types/common';
import { IFilter } from '@src/types/filter';
import { ISorting } from '@src/types/sorting';
import { formatMonth, getUTCTimezone, formatFullMonth } from '@src/utils/date_helpers';

import { IStatementDetailsFilter } from '@src/components/reconciliation_center/month_end_documents/balance_sheet_statements/balance_sheet_information_details/filter';
import DetailsPageTitle from '@src/components/ui/details_page_title';
import { Button } from '@src/components/ui_v2/buttons';
import { PlusIcon } from '@src/components/utils/icomoon';

import RequestStatementAction from './actions/request_document/action';
import Table from './table';

import styles from '@src/components/reconciliation_center/month_end_documents/balance_sheet_statements/balance_sheet_information_details/styles.module.scss';

interface IStatementDetailsListProps {
  businessId: TID,
  filter: IFilter<IStatementDetailsFilter>;
  balanceSheetStatements: IBalanceSheetStatement[],
  chartOfAccountId: TID,
  query: UseInfiniteQueryResult<any, Error>,
  sorting: ISorting<TStatementDetailsSortColumn>,
  chartOfAccountName: string,
  year: TYear,
  months: number,
  onCloseDetailsModal?: () => void,
  onUploadFile: () => void,
}

const StatementDetailsList = ({
  businessId,
  filter,
  balanceSheetStatements,
  chartOfAccountId,
  query,
  sorting,
  chartOfAccountName,
  year,
  months,
  onCloseDetailsModal,
  onUploadFile,
}: IStatementDetailsListProps): JSX.Element => {
  const bankStatementsDocRequestEnabled = window.configData.launch_darkly_enabled_global_features
    .includes(window.Docyt.Common.Constants.BANK_STATEMENTS_DOC_REQUEST_FLAG);

  const values: any = useMemo(() => {
    let result: Array<IStatementState> = [];

    balanceSheetStatements.forEach((bState) => {
      result = [
        ...result,
        {
          month:                     formatMonth(getUTCTimezone(new Date(bState.date))),
          id:                        bState.id,
          name:                      bState.name,
          statementFileUrl:          bState.statementFileUrl,
          statementFileName:         bState.statementFileName || '--',
          state:                     bState.state as TStatementState,
          statementDate:             bState.date,
          verifiedByName:            bState.verifiedByName || '--',
          createdAt:                 bState.createdAt,
          uploadedByName:            bState.uploadedByName || '--',
          requestedAt:               bState.requestedAt,
          docytId:                   bState.docytId,
          startingDate:              bState.startingDate,
          date:                      bState.date,
          description:               bState.description,
          hasNextVerifiedStatements: bState.hasNextVerifiedStatements,
        }];
    });

    return result;
  }, [balanceSheetStatements]);

  return (
    <div className={ styles['main-details'] }>
      <DetailsPageTitle
        breadcrumbs={ [
          { href: rcMonthEndDocmentsOtherDocumentsPath(businessId), title: 'Other Documents' },
          { href: rcMonthEndDocmentsOtherDocumentsStatementsPath(
            businessId,
            chartOfAccountId,
            filter.data?.year,
          ),
          title: chartOfAccountName },
        ] }
        title={ formatFullMonth(new Date(parseInt(year, 10), months - 1, 1)) }
      >
        <RequestStatementAction
          businessId={ businessId }
          chartOfAccountId={ chartOfAccountId }
          chartOfAccountName={ chartOfAccountName }
          months={ months }
          year={ year }
        />

        <Button
          className={ styles['upload-statement-button'] }
          prefixIcon={ <PlusIcon className="font-bold" fontSize={ 14 } /> }
          variant="primary"
          onClick={ onUploadFile }
        >
          Upload
        </Button>
      </DetailsPageTitle>
      <div className="banking-accounts-table-wrapper m-t-10">
        <Table
          balanceSheetStatements={ values }
          bankStatementsDocRequestEnabled={ bankStatementsDocRequestEnabled }
          businessId={ businessId }
          chartOfAccountId={ chartOfAccountId }
          query={ query }
          sorting={ sorting }
          onCloseDetailsModal={ onCloseDetailsModal }
        />
      </div>
    </div>
  );
};

export default StatementDetailsList;
