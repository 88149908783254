import React from 'react';

import Icomoon, { IIcomoonIconProps } from './icomoon';

const BusinessDriveIcon = ({ ...props } : IIcomoonIconProps) => {
  return (
    <Icomoon { ...props } iconName="inboxbusiness_inbox" />
  );
};
export default BusinessDriveIcon;
