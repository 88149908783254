import React from 'react';

import { IPendingReviewProps } from '@src/types/common_operational_dashboard';

import CorporateCardsTooltip from '@src/components/operational_dashboard/components/tooltips/corporate_cards_tooltip';
import MissingRevenueTooltip from '@src/components/operational_dashboard/components/tooltips/pending_review_tooltip';
import {
  IBusinessOwnerConfigFormType,
  missingRevenueReportsTooltipData,
  unbalancedReportsTooltipData,
} from '@src/components/operational_dashboard/helper';
import { isSetupStatusComplete } from '@src/components/operational_dashboard/helper/functional';
import { TSetupStatus } from '@src/components/operational_dashboard/types';

import HoverTooltipWrapper from './hover_tooltip_wrapper';

import styles from './style.module.scss';

interface IProcessPendingReviewDataProps {
  pendingReviewProp: IPendingReviewProps;
  preferences: IBusinessOwnerConfigFormType;
  setupStatus?: TSetupStatus;
}

const ProcessPendingReviewData = ({
  pendingReviewProp,
  preferences,
  setupStatus,
}: IProcessPendingReviewDataProps) => {
  return (
    <div className={ styles['container-flex'] }>
      {preferences.revenue?.pendingReview?.missingRevenueReports && (
        <HoverTooltipWrapper
          content={ (
            <MissingRevenueTooltip
              cellData={ pendingReviewProp }
              item={ missingRevenueReportsTooltipData }
              title="Missing Revenue Reports"
            />
          ) }
        >
          <div className={ styles['box-container'] }>
            <span className={ styles['table-font'] }>
              {isSetupStatusComplete(setupStatus)
                ? 'NA'
                : pendingReviewProp.missingRevenueReports ?? '-'}
            </span>
          </div>
        </HoverTooltipWrapper>
      )}
      {preferences.revenue?.pendingReview?.unbalancedReports && (
        <HoverTooltipWrapper
          content={ (
            <CorporateCardsTooltip
              cellData={ pendingReviewProp }
              item={ unbalancedReportsTooltipData }
            />
          ) }
        >
          <div className={ styles['box-container'] }>
            <span className={ styles['table-font'] }>
              {isSetupStatusComplete(setupStatus)
                ? 'NA'
                : pendingReviewProp.unbalancedReports ?? '-'}
            </span>
          </div>
        </HoverTooltipWrapper>
      )}
    </div>
  );
};

export default ProcessPendingReviewData;
