import React from 'react';

import { IIssuesProps } from '@src/types/common_operational_dashboard';

import IssueCellTooltip from '@src/components/operational_dashboard/components/tooltips/tooltip_cardlist';
import { issuesTooltipData } from '@src/components/operational_dashboard/helper';
import { isSetupStatusComplete } from '@src/components/operational_dashboard/helper/functional';
import { ICommonProps } from '@src/components/operational_dashboard/types';

import HoverTooltipWrapper from './hover_tooltip_wrapper';
import NumberBox from './number_box';

import styles from './style.module.scss';

interface IIssuesCellProps extends IIssuesProps, ICommonProps {}
const IssueCell = ({
  disconnectedGeneralLedger,
  disconnectedRevenueIntegration,
  disconnectedBankAccounts,
  generalLedgerConnectionIssues,
  duplicateVendors,
  setupStatus,
}: IIssuesCellProps) => {
  if (isSetupStatusComplete(setupStatus)) {
    return <h2 className={ styles['setup-heading'] }>Complete Setup</h2>;
  }

  return (
    <div className={ styles['business-issue-container'] }>
      <div className={ styles['issue-icon-list'] }>
        <HoverTooltipWrapper
          content={ (
            <IssueCellTooltip
              items={ issuesTooltipData.disconnectedGeneralLedger }
            />
            ) }
        >
          <NumberBox value={ { value: disconnectedGeneralLedger, type: 'indicator' } } />
        </HoverTooltipWrapper>
        <HoverTooltipWrapper
          content={ (
            <IssueCellTooltip
              items={ issuesTooltipData.disconnectedRevenueIntegration }
              title={ `${disconnectedRevenueIntegration} Revenue Integrations Disconnected` }
            />
            ) }
        >
          <NumberBox
            isDanger
            value={ { value: disconnectedRevenueIntegration, type: 'number' } }
          />
        </HoverTooltipWrapper>
        <HoverTooltipWrapper
          content={ (
            <IssueCellTooltip
              items={ issuesTooltipData.disconnectedBankAccounts }
              title={ `${
                disconnectedBankAccounts ?? ''
              } Bank Accounts Disconnected` }
            />
            ) }
        >
          <NumberBox
            isDanger
            value={ { value: disconnectedBankAccounts, type: 'number' } }
          />
        </HoverTooltipWrapper>
        <HoverTooltipWrapper
          content={ (
            <IssueCellTooltip
              items={ issuesTooltipData.generalLedgerConnectionIssues }
              value={ generalLedgerConnectionIssues }
            />
            ) }
        >
          <NumberBox
            isWarning
            value={ { value: generalLedgerConnectionIssues, type: 'number' } }
          />
        </HoverTooltipWrapper>
        <HoverTooltipWrapper content={ (
          <IssueCellTooltip
            items={ issuesTooltipData.duplicateVendors }
            title=""
            value={ duplicateVendors }
          />
               ) }
        >
          <NumberBox
            isWarning
            value={ { value: duplicateVendors, type: 'number' } }
          />
        </HoverTooltipWrapper>

      </div>
    </div>
  );
};
export default IssueCell;
