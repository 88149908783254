import React, { FC, useCallback } from 'react';

import { useReconcile } from '@src/hooks/queries/bank_account_reconciliations';
import { IUpdateBankAccountReconciliationResponse } from '@src/requests/bank_account_reconciliations';
import { IBankAccountReconciliationWithLoading } from '@src/types/bank_account_reconciliations';

import { Button } from '@src/components/ui_v2/buttons';
import MutationStatus from '@src/components/utils/mutation_status';

import useModal from './modal';

interface ReconcileButtonProps {
  reconciliation: IBankAccountReconciliationWithLoading
}

const ReconcileButton: FC<ReconcileButtonProps> = ({ reconciliation }) => {
  const mutation = useReconcile();
  const { mutate } = mutation;

  const onReconciled = (resp?: IUpdateBankAccountReconciliationResponse) => {
    if (!resp) return;

    window.Docyt.vent.trigger(
      'month_end:ba_reconciliation:status_change',
      resp.baReconciliation.status,
    );
  };

  const reconcile = useCallback((note?: string) => {
    mutate(
      { id: reconciliation.id, note },
      { onSettled: onReconciled },
    );
  }, [reconciliation, mutate]);

  const modal = useModal({ onDone: reconcile });

  return (
    <>
      <MutationStatus mutation={ mutation } />
      <modal.Component { ...modal.props } />
      <Button disabled={ reconciliation.calculateLoading } variant="primary" onClick={ modal.open }>
        Mark as Reconciled
      </Button>
    </>
  );
};

export default React.memo(ReconcileButton);
