import React, { useState } from 'react';

import { IUseModalProps, makeUseModal } from '@src/hooks/modal';

import Modal from '@src/components/ui/modal';
import Text from '@src/components/ui/text';
import { Button } from '@src/components/ui_v2/buttons';
import SearchInput from '@src/components/ui_v2/search_dropdown/search_input';

import { useGetIntegrationExternalSystemData } from './hooks';

import styles from './styles.module.scss';

interface IConnectRevenueSystemModalProps extends IUseModalProps {}
const ConnectRevenueSystemModal = ({
  isOpen,
  onDone,
}: IConnectRevenueSystemModalProps) => {
  const [searchValue, setSearchValue] = useState<string>('');
  const externalSystemList = useGetIntegrationExternalSystemData();

  const filteredSys = externalSystemList.filter((sys) => sys.label.toLowerCase().includes(searchValue.toLowerCase()));

  return (
    <Modal
      className={ styles['modal-template-excel'] }
      show={ isOpen }
      title="Connect Revenue System"
    >
      <Modal.Body>
        <Text fontSize={ 14 } mb={ 8 } mt={ 16 }>
          Select a revenue system or setup manually to continue. Only the business admins have
          access to connect these revenue systems.
        </Text>
        <SearchInput
          hideClear
          placeholder="Search Revenue System"
          value={ searchValue }
          width="100%"
          onChange={ setSearchValue }
        />
        <div className={ styles['revenue-system-list'] }>
          {filteredSys.map((system) => (
            <div key={ system.id } className={ styles['revenue-system-item'] }>
              <div className={ styles['revenue-system-name-wrapper'] }>
                <div className={ styles['revenue-system-indicator'] } />
                <span className={ styles['revenue-system-name'] }>{system.label}</span>
              </div>
              <span className={ styles['revenue-system-status'] }>
                {system.connected}
                {' '}
                Connected
              </span>
              <Button className={ styles['connect-button'] } variant="link">
                Connect
              </Button>
            </div>
          ))}
        </div>
        <div className={ styles['setup-wrapper'] }>
          <div className={ styles['separator-text'] }>
            <span>Couldn&#39;t find your revenue system?</span>
          </div>
          <Button variant="link"> Setup Manually</Button>
        </div>
      </Modal.Body>
      <Modal.Footer className="modal-footer-v2">
        <div className={ styles['cancel-button'] }>
          <Button variant="link" onClick={ onDone }>
            Cancel
          </Button>
        </div>
      </Modal.Footer>
    </Modal>
  );
};

const useConnectRevenueSystemModal = makeUseModal(ConnectRevenueSystemModal);

export { useConnectRevenueSystemModal, ConnectRevenueSystemModal as default };
