export const Family = {
  CollectionTableColumns:           'collection_table_columns',
  CollectionTableColumnsWithHidden: 'collection_table_columns_with_hidden',
  CollectionTableHiddenColumns:     'collection_table_hidden_columns',

  Filter:        'filter',
  FilterCounter: 'filter_counter',
  FilterValue:   'filter_value',

  ItemsSelector: 'items_selector',

  ReportUpdatingState:       'report_updating_state',
  ReportPeriodType:          'report_period_type',
  PickMultiMonths:           'pick_multi_months',
  PickMultiDaily:            'pick_multi_daily',
  ReportFilePreparing:       'report_file_preparing',
  SearchColumnValue:         'search_column_value',
  MultiSelectAddValue:       'multi_select_add_value',
  ReportsExportValue:        'reports_export_value',
  BudgetsSelectAddValue:     'budgets_select_add_value',
  ForecastSelectAddValue:    'forecast_select_add_value',
  HideZeroRows:              'hide_zero_rows',
  ReportFilterDateRange:     'report_filter_date_range',
  reportRefreshState:        'report_refresh_state',
  AddTransactionFieldsState: 'add-transaction-fields-state',
};
