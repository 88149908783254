import { useMutation, useQuery, useQueryClient } from 'react-query';

import { QueryKey } from '@src/constants/query_keys';
import {
  getDashboardTableData,
  getDashboardTablePreferences,
  IGetDashboardTableServiceParams,
  IGetDashboardTableServiceResponse,
  updateOperationDashboardPreferences,
  IUpdateOperationDashboardPreferencesBody,
} from '@src/requests/operational_dashboard/operation_dashboard_service';

import { IBusinessOwnerConfigFormType } from '@src/components/operational_dashboard/helper';
import { IDashboardData } from '@src/components/operational_dashboard/types';

import { createUseGetInfiniteCollection } from '../infinite_collection_queries';

const useGetOperationDashboardPreferences = (
  params?: IGetDashboardTableServiceParams,
) => {
  return useQuery<IBusinessOwnerConfigFormType, Error>(
    [QueryKey.operationDashboardPreferences, params],
    () => getDashboardTablePreferences(params),
    {
      keepPreviousData: true,
    },
  );
};

const useUpdateOperationDashboardPreferences = () => {
  const queryClient = useQueryClient();
  return useMutation<
    {},
    Error,
    IUpdateOperationDashboardPreferencesBody
  >(updateOperationDashboardPreferences, {
    onSuccess: () => {
      queryClient.invalidateQueries(QueryKey.operationDashboardPreferences);
    },
  });
};

const useGetDashboardTableData = createUseGetInfiniteCollection<
  IDashboardData,
  IGetDashboardTableServiceParams,
  IGetDashboardTableServiceResponse
>({
  queryKey: QueryKey.operationDashboardTableData,
  request:  getDashboardTableData,
});

export {
  useGetOperationDashboardPreferences,
  useGetDashboardTableData,
  useUpdateOperationDashboardPreferences,
};
