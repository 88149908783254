/* eslint-disable max-len */
import React from 'react';

import toastr from '@lib/toastr';
import { useBankAccountReconciliationContext } from '@src/hooks/contexts/bank_account_reconciliation_context';
import { useBankStatementContext } from '@src/hooks/contexts/bank_statement_context';
import { useBusinessContext } from '@src/hooks/contexts/business_context';
import { useGetAdjustmentEntriesCsv } from '@src/requests/adjustment_entries';

import JournalDrawer from '@src/components/reconciliation_center/journal_entries/header/journal_drawer';
import useDrawer from '@src/components/reconciliation_center/journal_entries/header/use_drawer';
import Table from '@src/components/reconciliation_center/journal_entries/table';
import { getReconciliationDateRange } from '@src/components/reconciliation_center/month_end/utils';
import DrawerUpIcon from '@src/components/utils/icomoon/drawer-up';

import styles from '../styles.module.scss';

const ViewIcon = () => {
  const { isOpen, open, close } = useDrawer();
  const reconciliation = useBankAccountReconciliationContext();
  const bankStatement = useBankStatementContext();
  const [startDate, endDate] = getReconciliationDateRange(reconciliation, bankStatement);
  const business = useBusinessContext();
  const { mutateAsync, isLoading } = useGetAdjustmentEntriesCsv();
  const params = { business_id: business.id, filter: { reconciliation_id: reconciliation.id } };

  const download = async () => {
    try {
      const data = await mutateAsync(params);
      const downloadUrl = window.URL.createObjectURL(data);
      const link = document.createElement('a');
      link.href = downloadUrl;
      link.setAttribute('download', `reconciliation-${reconciliation.id}-adjustment-journal-entries.csv`);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      window.URL.revokeObjectURL(downloadUrl);
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    } catch (error) {
      toastr.error('Failed to download CSV.', 'Error');
    }
  };

  return (
    <>
      <DrawerUpIcon fontSize={ 22 } inColor="grey-500" onClick={ open } />
      <JournalDrawer drawerControl={ { isOpen, open, close, download, isDownloading: isLoading } } title="Adjustment Journal Entry">
        <div className={ styles['table-container'] }>
          <Table entryDate={ { gte: startDate, lte: endDate } } reconciliation={ reconciliation } />
        </div>
      </JournalDrawer>
    </>
  );
};

export default ViewIcon;
