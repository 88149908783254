import React, { memo } from 'react';

import { IRevenueProps } from '@src/types/common_operational_dashboard';

import LineGraph from '@src/components/operational_dashboard/components/custom_cells/line_graph';
import UndepositedRevenueTooltip from '@src/components/operational_dashboard/components/tooltips/undeposit_revenue_tooltip';
import {
  undepositedRevenueTooltipData,
} from '@src/components/operational_dashboard/helper';
import { progressUndepositedRevenueData } from '@src/components/operational_dashboard/helper/functional';
import {
  ICommonProps,
} from '@src/components/operational_dashboard/types';

import HoverTooltipWrapper from './hover_tooltip_wrapper';
import ProcessPendingReviewData from './process_pending_review_data';

import styles from './style.module.scss';

interface IRevenueCellProps extends IRevenueProps, ICommonProps {}

const RevenueCell = ({
  undepositedRevenue,
  pendingReview,
  preferences,
  setupStatus,
}: IRevenueCellProps) => {
  return (
    <div className={ styles['business-column-container'] }>
      {undepositedRevenue
        && preferences?.revenue?.undepositedRevenue?.undepositedRevenueAmount && (
          <HoverTooltipWrapper
            content={ (
              <UndepositedRevenueTooltip
                cellData={ undepositedRevenue }
                items={ undepositedRevenueTooltipData }
                title=""
              />
            ) }
          >
            <LineGraph
              isCurrency
              segments={ progressUndepositedRevenueData(undepositedRevenue) }
              setupStatus={ setupStatus }
            />
          </HoverTooltipWrapper>
      )}
      {pendingReview
        && preferences?.revenue?.pendingReview
        && (
        <ProcessPendingReviewData
          pendingReviewProp={ pendingReview }
          preferences={ preferences }
          setupStatus={ setupStatus }
        />
        )}
    </div>
  );
};

export default memo(RevenueCell);
