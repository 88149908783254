import React, { FC } from 'react';

import { useQueryClient } from 'react-query';

import toastr from '@lib/toastr';
import { QueryKey } from '@src/constants/query_keys';
import { useUnreconcile } from '@src/hooks/queries/bank_account_reconciliations';
import { IBankAccountReconciliationWithLoading } from '@src/types/bank_account_reconciliations';

import { useConfirmDeleteModal } from '@src/components/common/confirm_delete';
import ActionsDropdown from '@src/components/ui_v2/actions_dropdown';
import MutationStatus from '@src/components/utils/mutation_status';

interface UnreconcileButtonProps {
  reconciliation: IBankAccountReconciliationWithLoading
}

const UnreconcileButton: FC<UnreconcileButtonProps> = ({ reconciliation }) => {
  const mutation = useUnreconcile();
  const { mutateAsync } = mutation;
  const queryClient = useQueryClient();

  const unreconcile = async () => {
    try {
      await mutateAsync(reconciliation.id);
      await queryClient.invalidateQueries(
        [QueryKey.bankAccountReconciliation, reconciliation.id],
      );
      toastr.success('Successfully un-reconcile', 'Success');
    } catch (error) {
      const errorMessage = (error as Error)?.message || 'An unknown error occurred';
      toastr.error(`Failed to un-reconcile: ${errorMessage}`, 'Error');
    }
  };

  const modal = useConfirmDeleteModal({ onDone: unreconcile });

  return (
    <>
      <MutationStatus mutation={ mutation } />
      <modal.Component
        confirmStyle="primary"
        confirmTitle="Confirm"
        text="Are you sure you want to re-open this reconciliation?"
        title="Re-open reconciliation"
        { ...modal.props }
      />
      <ActionsDropdown.Action onSelect={ modal.open }>
        Re-open
      </ActionsDropdown.Action>
    </>
  );
};

export default UnreconcileButton;
