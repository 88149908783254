import React, { useCallback, useEffect, useState } from 'react';

// @ts-ignore
import FileViewer from 'react-file-viewer';

import { NewDocumentIcon } from '@src/components/utils/img_icons';

import styles from '../styles.module.scss';

interface IBalanceSheetStatementViewProps {
  fileName?: string,
  fileUrl: string | Uint8Array,
  showStatementName?: boolean,
  className?: string,
  fileType?: string,
}

const allowedFileFormat = window.configData.all_allowed_file_upload_format;

const BalanceSheetStatementView = ({
  fileName,
  fileUrl,
  showStatementName = true,
  className = 'height-100-percent',
  fileType,
}: IBalanceSheetStatementViewProps) => {
  const [url, setUrl] = useState<string>(fileType?.includes('txt') ? '' : fileUrl as string);

  useEffect(() => {
    if (fileType?.includes('txt')) {
      if (!String(fileUrl).includes('base64')) {
        fetch(fileUrl as string)
          .then(response => response.text())
          .then(text => {
            setUrl(text);
          })
      } else {
        setUrl(fileUrl as string);
      }
    }
  }, [fileUrl, setUrl, fileType]);

  const renderViewer = useCallback(() => {
    if (!allowedFileFormat.includes(fileType as string)) {
      return (
        <div className={ styles['file-unsupported-container'] }>
          <div className={ styles.detail }>
            <NewDocumentIcon />
            <p className="m-t-20 font-16 text-danger">Preview not supported.</p>
          </div>
        </div>
      );
    }

    if (fileName?.includes('txt')) {
      if (String(fileUrl)?.includes('base64')) {
        return (
          <iframe className="width-100-percent height-100-percent" src={ url } title={ fileName } />
        );
      }
  
      return (
        <iframe className="width-100-percent height-100-percent" srcDoc={ url } title={ fileName } />
      );
    }

    return (
      <FileViewer
        filePath={ url }
        fileType={ fileType }
      />
    );
  }, [fileType, fileUrl, url, fileName]);

  return (
    <div className={ className }>
      { showStatementName && <span className="font-bold font-18">{ fileName }</span> }
      { renderViewer() }
    </div>
  );
};

export default BalanceSheetStatementView;
