/* eslint-disable max-len */
import React, { FC, useCallback, useMemo, useState } from 'react';

import { useBankAccountReconciliationContext } from '@src/hooks/contexts/bank_account_reconciliation_context';
import { useBankStatementContext } from '@src/hooks/contexts/bank_statement_context';
import { useBusinessContext } from '@src/hooks/contexts/business_context';
import {
  bankStatementsPath,
  allTransactionsPath,
} from '@src/routes';
import { IAdjustmentEntry } from '@src/types/adjustment_entries';
import { IReconciliationPaymentAccount } from '@src/types/reconciliation_payment_accounts';

import { ExportCSVBanner } from '@src/components/common/actions/export_csv';
import JournalDrawer from '@src/components/reconciliation_center/journal_entries/header/journal_drawer';
import useDrawer from '@src/components/reconciliation_center/journal_entries/header/use_drawer';
import AdjustmentEntryAddForm from '@src/components/reconciliation_center/month_end/adjustment_journal_entries/add_form';
import AdjustmentEntryEditForm from '@src/components/reconciliation_center/month_end/adjustment_journal_entries/edit_form';
import EndingBalanceChangedBanner
  from '@src/components/reconciliation_center/month_end/ending_balance_changed_banner/ending_balance_changed_banner';
import Spinner from '@src/components/ui/spinner';
import DetailsRegion from '@src/components/ui_v2/layout/details_region';
import Table from '@src/components/ui_v2/table';
import TableSection from '@src/components/ui_v2/table_section';

import Drawers from './drawers';
import MonthEndHeader from './header';
import PushedByDocytTransactions from './pushed_by_docyt_transactions';
import MonthEndRow from './row';
import StatementEndingBalance from './statement_ending_balance/statement_ending_balance';
import Step from './step';
import { buildItemTypesFromArray, usePaymentAccountName, getReconciliationDateRange, isVerified, nonZeroAmount } from './utils';

import styles from './styles.module.scss';

interface AdjustmentEntryFormProps {
  adjustmentEntry?: IAdjustmentEntry;
  close: () => void;
}

const AdjustmentEntryForm: FC<AdjustmentEntryFormProps> = ({ adjustmentEntry, close }) => {
  if (adjustmentEntry) {
    return <AdjustmentEntryEditForm close={ close } id={ adjustmentEntry!.id } />;
  }

  return <AdjustmentEntryAddForm close={ close } />;
};

const MonthEndTable = ({ reconciliationPaymentAccounts }: { reconciliationPaymentAccounts: IReconciliationPaymentAccount[] }) => {
  const business = useBusinessContext();
  const reconciliation = useBankAccountReconciliationContext();
  const bankStatement = useBankStatementContext();
  const { isOpen, open, close } = useDrawer();
  const [adjustmentEntry, setAdjustmentEntry] = useState<IAdjustmentEntry>();

  const items = useMemo(
    () => buildItemTypesFromArray(reconciliation.bankAccountReconciliationItems),
    [reconciliation.bankAccountReconciliationItems],
  );
  const [dismiss, showDismiss] = React.useState(true);

  const tableHeader = (
    <Table.Head>
      <Table.Row>
        <Table.HCell width="35%" />
        <Table.HCell>Open Items</Table.HCell>
        <Table.HCell width="7%" />
        <Table.HCell width="20%">Acknowledged On</Table.HCell>
        <Table.HCell width="20%">Acknowledged By</Table.HCell>
      </Table.Row>
    </Table.Head>
  );

  const bankStatementsDocRequestEnabled = window.configData.launch_darkly_enabled_global_features
    .includes(window.Docyt.Common.Constants.BANK_STATEMENTS_DOC_REQUEST_FLAG);
  const bankStatementPathStr = bankStatementsPath(
    business.id,
    bankStatement?.docytId,
    bankStatement?.state,
    bankStatementsDocRequestEnabled,
  );

  const [startDate, endDate] = getReconciliationDateRange(reconciliation, bankStatement);
  const paymentAccountName = usePaymentAccountName();
  const documentType = items.undocumentedTransactions.itemType === 'undocumented_transactions' ? 'undocumented' : '';
  const undocumentedTransactionsPath = allTransactionsPath(
    business.id,
    {
      transaction_date_start: startDate,
      transaction_date_end:   endDate,
      has_related_document:   documentType,
      ...paymentAccountName,
    },
  );

  const handleDismissClick = () => {
    showDismiss(false);
  };

  const openDrawer = useCallback((item?: IAdjustmentEntry) => {
    setAdjustmentEntry(item);
    open();
  }, [open]);

  const tipsMake = () => {
    let domWrap = null;
    if (reconciliation.calculateLoading) {
      domWrap = (
        <div className={ styles.tips }>
          <span className={ styles['tips-text'] }>
            <i className="icon font-20 icon-clock" />
            Please wait while the data is being updated...
          </span>
        </div>
      );
    } else {
      domWrap = (
        <div className={ `${styles.tips} ${styles['tips-success']}` }>
          <span className={ styles['tips-text'] }>
            <i className="icon icon-success font-20">
              <span className="path1" />
              <span className="path2" />
            </i>
            The account is up to date for the current month.
          </span>
          <span
            className={ styles['tips-dismiss'] }
            role="button"
            tabIndex={ 0 }
            onClick={ handleDismissClick }
          >
            Dismiss
          </span>
        </div>
      );
    }
    return domWrap;
  };

  return (
    <DetailsRegion>
      <JournalDrawer drawerControl={ { isOpen, open, close } } title={ adjustmentEntry ? 'Edit Adjustment Entry' : 'Add Adjustment Entry' }>
        <AdjustmentEntryForm adjustmentEntry={ adjustmentEntry } close={ close } />
      </JournalDrawer>
      <MonthEndHeader endDate={ endDate } startDate={ startDate } />
      <ExportCSVBanner atomKey="monthEndTransactions" />
      <EndingBalanceChangedBanner />
      {dismiss && tipsMake()}
      {reconciliation.calculateLoading && <Spinner localSpinnerId="month-end-table-body" />}
      <div id="month-end-table-body">
        <TableSection>
          <TableSection.Caption className={ styles['table-section-caption'] }>Reconcile</TableSection.Caption>
          <TableSection.Panel className={ styles['table-section-panel'] }>
            <Table className={ styles['main-table'] }>
              { tableHeader }
              <Table.Body>
                <Step tooltip="Docyt collects real time bank feed for your bank accounts using third party banking APIs. This reconciliation step ensures that there are no missed or duplicate transactions.">
                  { isVerified(bankStatement) ? <span className={ styles['arrow-down'] } /> : null }
                  Step 1: Bank Feed Reconciliation
                </Step>

                <MonthEndRow
                  hasSecondLevelRows
                  item={ items.bankFeedVerification }
                  linkTo={ bankStatementPathStr }
                />

                <PushedByDocytTransactions item={ items.pushedByDocytTransactions } items={ items } />

                <MonthEndRow
                  item={ items.uncategorizedTransactions }
                  linkTo={ <Drawers.UncategorizedTransactions reconciliationItem={ items.uncategorizedTransactions } /> }
                />

                <MonthEndRow
                  item={ items.flaggedTransactions }
                  linkTo={ <Drawers.FlaggedTransactions reconciliationItem={ items.flaggedTransactions } /> }
                />

                <MonthEndRow
                  item={ items.unmatchedTransfers }
                  linkTo={ <Drawers.UnmatchedTransfers endDate={ endDate } reconciliationItem={ items.unmatchedTransfers } reconciliationPaymentAccounts={ reconciliationPaymentAccounts } startDate={ startDate } /> }
                />

                <Step tooltip="This reconciliation step ensures that all of your journal entries in general ledger are accounted for in Docyt. It requires the bank balance in QuickBooks to match with adjusted ledger balance in Docyt.">
                  Step 2: Ledger Balance Reconciliation
                </Step>

                <StatementEndingBalance
                  items={ items }
                  openDrawer={ openDrawer }
                />
              </Table.Body>
            </Table>
          </TableSection.Panel>
        </TableSection>

        <TableSection>
          <TableSection.Caption className={ styles['table-section-caption'] }>Acknowledge documentation status</TableSection.Caption>
          <TableSection.Panel className={ styles['table-section-panel'] }>
            <Table className={ styles['main-table'] }>
              { tableHeader }
              <Table.Body>
                <MonthEndRow
                  item={ items.undocumentedTransactions }
                  linkTo={ undocumentedTransactionsPath }
                />
              </Table.Body>
            </Table>
          </TableSection.Panel>
        </TableSection>
      </div>
    </DetailsRegion>
  );
};

export default React.memo(MonthEndTable);
