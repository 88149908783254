/* eslint-disable max-len */
import React, { FC } from 'react';

import { IBankAccountReconciliationItemTypes } from '@src/types/bank_account_reconciliations';
import { currencyLocaleFormatter } from '@src/utils/currency';

import { Button } from '@src/components/ui_v2/buttons';
import DetailsRegion from '@src/components/ui_v2/layout/details_region';
import { EqualSignCircle, HandRightAlt, MinusSignCircle } from '@src/components/utils/icomoon';

import ExcludedAndNotPushed from './excluded_and_not_pushed';
import { ICollection } from './hooks';
import PushedOutside from './pushed_outside';
import { STEP_MATCH } from './wizard_step';

import styles from '@src/components/reconciliation_center/month_end/drawers/styles.module.scss';

interface StepAcknowledgeProps {
  pushedOutsideCollection: ICollection,
  excludedAndNotPushedCollection: ICollection,
  setStep: (step: number) => void,
  items: IBankAccountReconciliationItemTypes,
  closeDrawer?: () => void
}

const StepAcknowledge: FC<StepAcknowledgeProps> = ({
  pushedOutsideCollection,
  excludedAndNotPushedCollection,
  setStep,
  items,
}) => {
  const pushedOutsideTotalAmount = parseFloat(pushedOutsideCollection.query.data?.pages[0]?.meta?.totalAmount || '0');
  const excludedAndNotPushedTotalAmount = parseFloat(excludedAndNotPushedCollection.query.data?.pages[0]?.meta?.totalAmount || '0');
  const itemAcknowledged = items.additionalUnclearedBalance?.status === 'acknowledged';

  return (
    <div className={ styles.review }>
      <div className={ styles['review-content'] }>
        <div className={ styles['review-toolbar'] }>
          <div className={ styles['review-toolbar-tip'] }>
            <HandRightAlt fontSize={ 16 } />
            { ' ' }
            Acknowledge the difference of unmatched transactions.
          </div>
        </div>
        <DetailsRegion>
          <div className={ styles['review-tables'] }>
            <div className={ styles['review-table'] }>
              <PushedOutside
                showSelect
                collection={ pushedOutsideCollection }
                rowSelectable={ false }
              />
            </div>
            <div className={ styles['review-table'] }>
              <ExcludedAndNotPushed
                showSelect
                collection={ excludedAndNotPushedCollection }
                rowSelectable={ false }
              />
            </div>
          </div>
        </DetailsRegion>
      </div>
      <div className={ styles['review-footer'] }>
        <div>
          {
            !itemAcknowledged && (
              <Button variant="link" onClick={ () => setStep(STEP_MATCH) }>
                Back
              </Button>
            )
          }
        </div>
        <div style={ { display: 'flex', justifyContent: 'center', flex: 1 } }>
          <div className={ styles['acknowledge-summary'] }>
            <div>
              <div>
                Transactions Pushed to GL Outside Docyt
              </div>
              <div className={ styles['acknowledge-summary-amount'] }>
                { currencyLocaleFormatter(pushedOutsideTotalAmount) }
              </div>
            </div>
            <div>
              <MinusSignCircle fontSize={ 32 } />
            </div>
            <div>
              <div>
                Excluded and Not Pushed to Ledger Transactions
              </div>
              <div className={ styles['acknowledge-summary-amount'] }>
                { currencyLocaleFormatter(excludedAndNotPushedTotalAmount) }
              </div>
            </div>
            <div>
              <EqualSignCircle fontSize={ 32 } />
            </div>
            <div>
              <div>
                Additional Uncleared Balance
              </div>
              <div className={ styles['acknowledge-summary-amount'] }>
                {
                  currencyLocaleFormatter(pushedOutsideTotalAmount - excludedAndNotPushedTotalAmount)
                }
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default StepAcknowledge;
