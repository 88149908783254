/* eslint-disable max-len */
import React, { FC } from 'react';

import classNames from 'classnames';

import { IBankAccountReconciliationWithLoading } from '@src/types/bank_account_reconciliations';

import ActionsDropdown from '@src/components/ui_v2/actions_dropdown';
import Dropdown from '@src/components/ui_v2/dropdown';

import DownloadButton from './download_button';
import UnreconcileButton from './unreconcile_button';

import styles from '../styles.module.scss';

interface ActionsForReconciledProps {
  reconciliation: IBankAccountReconciliationWithLoading
}

const ActionsForReconciled:FC<ActionsForReconciledProps> = ({ reconciliation }) => {
  const button = (
    <Dropdown.ToggleButton className={ classNames(styles['acknowledged-toggle-button'], styles['text-arrow-down'], 'acknowledge-button') }>
      Reconciled
    </Dropdown.ToggleButton>
  );

  return (
    <ActionsDropdown toggleButton={ button }>
      <UnreconcileButton reconciliation={ reconciliation } />
      <DownloadButton reconciliation={ reconciliation } />
    </ActionsDropdown>
  );
};

export default ActionsForReconciled;
