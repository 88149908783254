/* eslint-disable max-len */
import React, { useCallback } from 'react';

import { IUseModalProps, makeUseModal } from '@src/hooks/modal';

import Modal from '@src/components/ui/modal';

import Form from './form';

interface IAcknowledgeModalProps extends Omit<IUseModalProps, 'onDone'> {
  onDone: (note?: string) => void,
}

interface IAcknowledgeFormValues {
  note?: string,
}

const AcknowledgeModal = ({
  onDone,
  isOpen,
  ...props
}: IAcknowledgeModalProps) => {
  const handleSubmit = useCallback((data: IAcknowledgeFormValues) => {
    onDone(data.note);
  }, [onDone]);

  return (
    <Modal.Form
      proceedTitle="Confirm"
      show={ isOpen }
      title="Acknowledge"
      { ...props }
    >
      { ({ formId }) => (<Form formId={ formId } onSubmit={ handleSubmit } />) }
    </Modal.Form>
  );
};

export default makeUseModal(AcknowledgeModal);
