import React from 'react';

import { TID } from '@src/types/common';
import { IDataExportData } from '@src/types/data_exports';

import ActionsDropdown from '@src/components/ui/actions_dropdown';
import Text from '@src/components/ui/text';

import AddTransactionAction from './add_transaction_action';
import ExportCSVAction from './export_csv_action';

import styles from '../../styles.module.scss';

interface ITransactionsHeaderProps {
  businessId: TID,
  exportParams: IDataExportData['exportParams'],
  type: 'active' | 'excluded',
  isAdmin: boolean,
  isTrash: boolean,
}

const TransactionsHeader = ({
  businessId,
  exportParams,
  type,
  isAdmin,
  isTrash,
}: ITransactionsHeaderProps): JSX.Element => {
  const url = (transactionType: string) => {
    return `/businesses/${businessId}/banking_accounts/transactions/${transactionType}`;
  };

  return (
    <div className="page-main-header clearfix">
      <div className={ styles['header-container'] }>
        <div className={ styles['header-title'] }>
          <Text fontSize={ 24 } fontVariant="bold">{ isTrash ? 'Trash' : 'Transactions' }</Text>
        </div>
        {
          !isTrash && (
            <ul className="nav sub-header-menu m-t-0 m-b-0">
              <li className={ `m-r-24 ${(!type || type === 'active') && 'active'}` }>
                <a
                  href={ url('active') }
                  role="button"
                  tabIndex={ 0 }
                >
                  ACTIVE
                </a>
              </li>
              <li className={ `m-r-24 ${type === 'excluded' && 'active'}` }>
                <a
                  href={ url('excluded') }
                  role="button"
                  tabIndex={ 0 }
                >
                  EXCLUDED
                </a>
              </li>
            </ul>
          )
        }
        <div className="display-flex align-items-center">
          { !isTrash && isAdmin && (
            <AddTransactionAction />
          ) }
          <ActionsDropdown>
            <ExportCSVAction exportParams={ exportParams } />
          </ActionsDropdown>
        </div>
      </div>
    </div>
  );
};

export default React.memo(TransactionsHeader);
