import { object, boolean } from 'yup';

interface IExpensePreferences {
  billPay?: {
    uploadedInvoices?: boolean;
    approvedInvoices?: boolean;
    readyToPayInvoices?: boolean;
  };
  corporateCards?: {
    unapprovedReceipts?: boolean;
  };
  expenseReports?: {
    employeeReimbursements?: boolean;
    myReimbursements?: boolean;
  };
}

interface IRevenuePreferences {
  undepositedRevenue?: {
    undepositedRevenueAmount?: boolean;
  };
  pendingReview?: {
    missingRevenueReports?: boolean;
    unbalancedReports?: boolean;
  };
}

interface IReconciliationPreferences {
  uncategorizedTransactions?: boolean;
  unclearedDocuments?: boolean;
  missingBankStatements?: boolean;
  closingStatus?: boolean;
}

interface ICollaborationPreferences {
  flaggedItems?: {
    flaggedInvoices?: boolean;
    flaggedReceipts?: boolean;
    flaggedTransactions?: boolean;
  };
  documentRequests?: {
    reviewed?: boolean;
    open?: boolean;
  };
  unreadMessages?: {
    unreadMessagesCount?: boolean;
  };
}

interface IMailroomPreferences {
  unreviewedDocuments?: boolean;
}

interface IBusinessOwnerConfigFormType {
  expense?: IExpensePreferences;
  revenue?: IRevenuePreferences;
  reconciliation?: IReconciliationPreferences;
  collaboration?: ICollaborationPreferences;
  mailroom?: IMailroomPreferences;
}

const businessOwnerFilterValidation = object({
  expense: object({
    billPay: object({
      uploadedInvoices:   boolean().optional(),
      approvedInvoices:   boolean().optional(),
      readyToPayInvoices: boolean().optional(),
    }),
    corporateCards: object({
      unapprovedReceipts: boolean().optional(),
    }),
    expenseReports: object({
      employeeReimbursements: boolean().optional(),
      myReimbursements:       boolean().optional(),
    }),
  }),
  revenue: object({
    undepositedRevenue: object({
      undepositedRevenueAmount: boolean().optional(),
    }),
    pendingReview: object({
      missingRevenueReports: boolean().optional(),
      unbalancedReports:     boolean().optional(),
    }),
  }),
  reconciliation: object({
    uncategorizedTransactions: boolean().optional(),
    unclearedDocuments:        boolean().optional(),
    missingBankStatements:     boolean().optional(),
    closingStatus:             boolean().optional(),
  }),
  collaboration: object({
    flaggedItems: object({
      flaggedInvoices:     boolean().optional(),
      flaggedReceipts:     boolean().optional(),
      flaggedTransactions: boolean().optional(),
    }),
    documentRequests: object({
      reviewed: boolean().optional(),
      open:     boolean().optional(),
    }),
    unreadMessages: object({
      unreadMessagesCount: boolean().optional(),
    }),
  }),
  mailroom: object({
    unreviewedDocuments: boolean().optional(),
  }),
});

export {
  IExpensePreferences,
  IRevenuePreferences,
  IReconciliationPreferences,
  IBusinessOwnerConfigFormType,
  IMailroomPreferences,
  ICollaborationPreferences,
  businessOwnerFilterValidation,
};
