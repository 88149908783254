import React from 'react';

import { IReconciliationProps } from '@src/types/common_operational_dashboard';

import CustomPieChart from '@src/components/operational_dashboard/components/custom_cells/pie_chart';
import ClosingStatusTooltip from '@src/components/operational_dashboard/components/tooltips/closing_status_tooltip';
import {
  closingStatusTooltipData,
  missingBankStatementTooltipData,
  pieChartColors,
  uncategorizedTransactionsTooltipData,
  unclearedDocumentsTooltipData,
} from '@src/components/operational_dashboard/helper';
import { isSetupStatusComplete } from '@src/components/operational_dashboard/helper/functional';
import { ICommonProps } from '@src/components/operational_dashboard/types';

import HoverTooltipWrapper from './hover_tooltip_wrapper';
import ReconciliationItem from './reconciliation_item';

import styles from './style.module.scss';

interface IReconciliationCellProps extends IReconciliationProps, ICommonProps {}
const ReconciliationCell = ({
  uncategorizedTransactions,
  unclearedDocuments,
  missingBankStatements,
  closingStatus,
  preferences,
  setupStatus,
}: IReconciliationCellProps) => {
  return (
    <div className={ styles['business-reconciliation-container'] }>
      {preferences?.reconciliation?.uncategorizedTransactions
        && uncategorizedTransactions !== undefined && (
          <ReconciliationItem
            setupStatus={ setupStatus }
            tooltipData={ uncategorizedTransactionsTooltipData }
            tooltipValue={ uncategorizedTransactions }
            value={ uncategorizedTransactions }
          />
      )}
      {preferences?.reconciliation?.unclearedDocuments
        && unclearedDocuments !== undefined && (
          <ReconciliationItem
            setupStatus={ setupStatus }
            tooltipData={ unclearedDocumentsTooltipData }
            tooltipValue={ unclearedDocuments }
            value={ unclearedDocuments }
          />
      )}
      {preferences?.reconciliation?.missingBankStatements
        && missingBankStatements !== undefined && (
        <ReconciliationItem
          setupStatus={ setupStatus }
          tooltipData={ missingBankStatementTooltipData }
          tooltipValue={ missingBankStatements }
          value={ missingBankStatements }
        />
      )}
      {preferences?.reconciliation?.closingStatus && closingStatus && (
        <HoverTooltipWrapper
          content={ (
            <ClosingStatusTooltip
              cellData={ closingStatus }
              item={ closingStatusTooltipData }
            />
          ) }
        >
          <CustomPieChart
            colors={ pieChartColors }
            percent={
              isSetupStatusComplete(setupStatus) ? 0 : closingStatus.percent ?? 0
            }
            setupStatus={ setupStatus }
          />
        </HoverTooltipWrapper>
      )}
    </div>
  );
};
export default ReconciliationCell;
