export const Section = {
  AccountsReceivableInvoices:     'accounts_receivable_invoices',
  AccountsReceivablePayments:     'accounts_receivable_payments',
  AccountsPayablePastPayments:    'accounts_payable_past_payments',
  AccountsPayableAchTransactions: 'accounts_payable_ach_transactions',
  AccountsPayableService:         'accounts_payable_service',
  AchTransactionDocuments:        'accounts_payable_ach_transaction_documents',

  RevenueDeposits: 'revenue_deposits',
  DepositTracking: 'deposit_tracking',

  ReconcileIncome:  'reconcile_income',
  ReconcileRevenue: 'reconcile_revenue',

  RevenueCapture:    'revenue_capture',
  UnassignedRevenue: 'unassigned_revenue',

  BusinessReport: 'business_report',

  ManagementGroupSettings:        'management_group_settings',
  FinancialInsightsEmailSettings: 'financial_insights_email_settings',

  GlobalSearch:          'global_search',
  SelectMergeableVendor: 'select_mergeable_vendor',

  ExpenseReportsTrash: 'expense_reports_trash',

  ReportRefreshLogs:         'report_refresh_logs',
  MetricsReport:             'metrics_report',
  OperationalDashboardTable: 'operational_dashboard_table',
  ReconciliationCenter:      'reconciliation_center',
};
