/* eslint-disable max-len */
import React from 'react';

import { useBankAccountReconciliationContext } from '@src/hooks/contexts/bank_account_reconciliation_context';
import { TBankAccountReconciliationStatus } from '@src/types/bank_account_reconciliations';

import ActionsForReconciled from './actions_for_reconciled';
import ReconcileButton from './reconcile_button';

const Action = () => {
  const reconciliation = useBankAccountReconciliationContext();

  if (reconciliation.status === 'reconciled' as TBankAccountReconciliationStatus) {
    return <ActionsForReconciled reconciliation={ reconciliation } />;
  }

  return <ReconcileButton reconciliation={ reconciliation } />;
};

export default React.memo(Action);
