import React, { useCallback } from 'react';

import ActionsDropdown from '@src/components/ui_v2/actions_dropdown';
import DownloadDocumentIcon from '@src/components/utils/icomoon/download_document';

interface IDownloadRequestActionProps {
  fileUrl: string,
  fileName: string,
  isIcon?: boolean,
}

const DownloadRequestAction = ({
  fileUrl,
  fileName,
  isIcon = false,
}: IDownloadRequestActionProps): JSX.Element => {
  const downloadFile = useCallback(() => {
    if (fileUrl && fileName) {
      const link = document.createElement('a');
      link.href = fileUrl as string;
      link.download = fileName;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  }, [fileUrl, fileName]);

  return (
    <ActionsDropdown.Action
      icon={ isIcon ? <DownloadDocumentIcon fontSize={ 16 } /> : undefined }
      title="Download document"
      onClick={ downloadFile }
    />
  );
};

export default React.memo(DownloadRequestAction);
