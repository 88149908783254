import { useMemo } from 'react';

import flatten from 'lodash/flatten';
import { UseInfiniteQueryResult } from 'react-query';

import { Section } from '@src/constants/sections';
import { useGetDashboardTableData } from '@src/hooks/queries/operational_dashboard/operation_dashboard_service';
import { useSorting } from '@src/hooks/url_params';
import { IGetDashboardTableServiceResponse } from '@src/requests/operational_dashboard/operation_dashboard_service';
import { TSection } from '@src/types/common';
import { TDashboardTableServiceDocumentsSortColumn } from
  '@src/types/operational_dashboard/operation_dashboard_service';
import { ISorting, ISortingParams } from '@src/types/sorting';

import { IDashboardData } from './types';

interface IDashboardTableCollection {
  query: UseInfiniteQueryResult<IGetDashboardTableServiceResponse, Error>;
  records: IDashboardData[];
  section: TSection;
  sorting: ISorting<TDashboardTableServiceDocumentsSortColumn>;
}
const dashboardDefaultSorting: ISortingParams<TDashboardTableServiceDocumentsSortColumn> =
  {
    orderColumn:    'business',
    orderDirection: 'desc',
  };
const useDashboardTableCollection = (): IDashboardTableCollection => {
  const section = useMemo(() => {
    return {
      section: Section.OperationalDashboardTable,
    };
  }, []);
  const sorting = useSorting<TDashboardTableServiceDocumentsSortColumn>({
    section:        section.section,
    defaultSorting: dashboardDefaultSorting,
  });
  const query = useGetDashboardTableData({
    ...sorting.data,
  });
  const records = useMemo(() => {
    const pages = query?.data?.pages || [];
    return flatten(pages.map((p) => p.collection));
  }, [query?.data?.pages]);
  return {
    query,
    records,
    section,
    sorting,
  };
};
export { IDashboardTableCollection, useDashboardTableCollection };