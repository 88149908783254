/* eslint-disable max-len */
import React from 'react';

import moment from 'moment';

import { useBankAccountReconciliationContext } from '@src/hooks/contexts/bank_account_reconciliation_context';
import { useBankStatementContext } from '@src/hooks/contexts/bank_statement_context';
import { IAdjustmentEntry } from '@src/types/adjustment_entries';
import { IBankAccountReconciliationItemTypes } from '@src/types/bank_account_reconciliations';

import AdjustmentJournalEntries from '@src/components/reconciliation_center/month_end/adjustment_journal_entries';
import Drawers from '@src/components/reconciliation_center/month_end/drawers';
import { amountFormatterX, getReconciliationDateRange, isVerified } from '@src/components/reconciliation_center/month_end/utils';
import Table from '@src/components/ui_v2/table';

import StatementEndingBalanceItem from './statement_ending_balance_item';
import UnclearedBalanceDetail from './uncleared_balance_detail';

import styles from '@src/components/reconciliation_center/month_end/styles.module.scss';

const StatementEndingBalance: React.FC<{
  openDrawer: (item?: IAdjustmentEntry) => void;
  items: IBankAccountReconciliationItemTypes;
}> =
  ({ items, openDrawer }) => {
    const reconciliation = useBankAccountReconciliationContext();
    const bankStatement = useBankStatementContext();

    const dateRange = getReconciliationDateRange(reconciliation, bankStatement);
    const endDate = moment(dateRange[1]).format('MMM D, YYYY');

    const {
      unclearedBillPayments: { amount: unclearedBillPaymentsAmount = '0' },
      unclearedBillPaymentsCarriedOver: { amount: unclearedBillPaymentsCarriedOverAmount = '0' },
      unclearedTransfers: { amount: unclearedTransfersAmount = '0' },
      unclearedTransfersCarriedOver: { amount: unclearedTransfersCarriedOverAmount = '0' },
      difference: { amount: differenceAmount = '0' },
      differenceCarriedOver: { amount: differenceCarriedOverAmount = '0' },
      additionalUnclearedBalance: { amount: additionalUnclearedBalanceAmount = '0' },
      additionalUnclearedBalanceCarriedOver: { amount: additionalUnclearedBalanceCarriedOverAmount = '0' },
    } = items;

    const diff = differenceAmount ? parseFloat(differenceAmount) : 0;
    const totalDiff = diff + parseFloat(differenceCarriedOverAmount);

    const isVerifiedStatement = isVerified(bankStatement);
    const showAckButton = true;

    const statementEndingBalanceItems = [
      {
        title:          'Uncleared Expense and Revenue',
        total:          amountFormatterX(parseFloat(unclearedBillPaymentsAmount) + parseFloat(unclearedBillPaymentsCarriedOverAmount)),
        thisMonth:      amountFormatterX(parseFloat(unclearedBillPaymentsAmount)),
        carriedOver:    amountFormatterX(parseFloat(unclearedBillPaymentsCarriedOverAmount)),
        item:           items.unclearedBillPayments,
        drawer:         <Drawers.UnclearedBillPayments items={ items } />,
        downloadType:   'uncleared_bill_payments',
        downloadParams: {
          id:      reconciliation.id,
          item_id: [
            items.unclearedBillPayments?.id,
            items.unclearedBillPaymentsCarriedOver?.id,
          ],
        },
      },
      {
        title:          'Uncleared Transfers',
        total:          amountFormatterX(parseFloat(unclearedTransfersAmount) + parseFloat(unclearedTransfersCarriedOverAmount)),
        thisMonth:      amountFormatterX(parseFloat(unclearedTransfersAmount)),
        carriedOver:    amountFormatterX(parseFloat(unclearedTransfersCarriedOverAmount)),
        item:           items.unclearedTransfers,
        drawer:         <Drawers.UnclearedTransfers items={ items } />,
        downloadType:   'uncleared_transfers',
        downloadParams: {
          id:      reconciliation.id,
          item_id: [
            items.unclearedTransfers?.id,
            items.unclearedTransfersCarriedOver?.id,
          ],
        },
      },
      {
        title:          'Additional Uncleared Balance',
        total:          amountFormatterX(parseFloat(additionalUnclearedBalanceAmount) + parseFloat(additionalUnclearedBalanceCarriedOverAmount)),
        thisMonth:      amountFormatterX(additionalUnclearedBalanceAmount),
        carriedOver:    amountFormatterX(additionalUnclearedBalanceCarriedOverAmount),
        drawer:         <Drawers.AdditionalUnclearedBalance items={ items } />,
        downloadType:   'additional_uncleared_balance',
        downloadParams: {
          id:      reconciliation.id,
          item_id: [
            items.additionalUnclearedBalance?.id,
            items.additionalUnclearedBalanceCarriedOver?.id,
          ],
        },
        downloadColumnMappings: [
          ['category', 'Category'],
          ['transaction_date', 'Date'],
          ['transaction_type', 'Txn Type'],
          ['docyt_id', 'Ref Num'],
          ['description', 'Name [Class]'],
          ['vendor.name', 'Vendor'],
          ['memo', 'Memo'],
          ['service_document_splits', 'Split'],
          ['amount', 'Amount'],
        ] as [string, string][],
        customThisMonth: <UnclearedBalanceDetail items={ items } />,
        item:            items.additionalUnclearedBalance,
      },
      {
        title:            'Difference',
        total:            amountFormatterX(totalDiff),
        thisMonth:        amountFormatterX(diff),
        carriedOver:      amountFormatterX(differenceCarriedOverAmount),
        showDownloadIcon: false,
        downloadType:     'transactions_pushed_by_outside',
        downloadParams:   { id: reconciliation.id },
        titleAmountColor: totalDiff === 0 ? '#08a37a' : '#ff4848',
        emptyValue:       !isVerifiedStatement,
        tooltip:          isVerifiedStatement ? 'Difference = Ending balance in GL - (Statement ending balance + Uncleared Expense and Revenue + uncleared transfers + additional uncleared balance)' : '',
        item:             items.difference,
        noteRequired:     true,
        showAckButton,
      },
    ];

    return (
      <>
        <Table.Row className={ styles.row }>
          <Table.TextCell hideValueTooltip className={ styles['ending-balance-title'] }>
            Statement ending balance as of
            {' '}
            { endDate }
          </Table.TextCell>
          <Table.TextCell hideValueTooltip>
            <span className={ styles['ending-balance-title'] }>{ isVerifiedStatement ? amountFormatterX(bankStatement?.closingBalance) : '-' }</span>
          </Table.TextCell>
          <Table.IconCell />
          <Table.TextCell />
          <Table.UserCell />
        </Table.Row>

        {
          statementEndingBalanceItems.map((it) => <StatementEndingBalanceItem key={ it.title } data={ it } />)
        }

        <AdjustmentJournalEntries
          editable={ reconciliation.status !== 'acknowledged' }
          openDrawer={ openDrawer }
        />

        <Table.Row className={ styles.row }>
          <Table.TextCell hideValueTooltip className={ styles['ending-balance-title'] }>
            Ending balance in GL as of
            {' '}
            { endDate }
          </Table.TextCell>
          <Table.TextCell hideValueTooltip>
            <span className={ styles['ending-balance-title'] }>{ amountFormatterX(reconciliation.endingBalance) }</span>
          </Table.TextCell>
          <Table.IconCell />
          <Table.TextCell />
          <Table.UserCell />
        </Table.Row>
      </>
    );
  };

export default StatementEndingBalance;
