/* eslint-disable max-len */
import React, { useState } from 'react';

import { useQueryClient } from 'react-query';

import toastr from '@lib/toastr';
import { QueryKey } from '@src/constants/query_keys';
import { useBankAccountReconciliationContext } from '@src/hooks/contexts/bank_account_reconciliation_context';
import { useUnreconcile } from '@src/hooks/queries/bank_account_reconciliations';

import { Button } from '@src/components/ui_v2/buttons';
import { SpinnerIcon } from '@src/components/utils/fa_icons';

import WarningTriangleIcon from '../../../utils/icomoon/warning-triangle';

import styles from './styles.module.scss';

const EndingBalanceChangedBanner = () => {
  const baReconciliation = useBankAccountReconciliationContext();
  const { mutateAsync } = useUnreconcile();
  const queryClient = useQueryClient();
  const [isLoading, setIsLoading] = useState(false);

  const updateSidebarAccountIconStatus = () => {
    const element = document.querySelector(`.payment-account-select[data-id="${baReconciliation.reconciliationPaymentAccountId}"]`);

    if (element) {
      const warningIcon = element.querySelector('.icon-warning-triangle');
      if (warningIcon) {
        const newIcon = document.createElement('i');
        newIcon.className = 'icon icon-progress-arrow-orange font-20';
        newIcon.innerHTML = '<span class="path1"></span><span class="path2"></span><span class="path3"></span>';
        warningIcon.replaceWith(newIcon);
      }
    }
  };

  const handleClick = async () => {
    setIsLoading(true);
    try {
      await mutateAsync(baReconciliation.id);
      await queryClient.invalidateQueries(
        [QueryKey.bankAccountReconciliation, baReconciliation.id],
      );
      updateSidebarAccountIconStatus();
      toastr.success('Successfully un-reconcile', 'Success');
    } catch (error) {
      const errorMessage = (error as Error)?.message || 'An unknown error occurred';
      toastr.error(`Failed to un-reconcile: ${errorMessage}`, 'Error');
    } finally {
      setIsLoading(false);
    }
  };

  const showBanner = baReconciliation.status === 'reconciled' && baReconciliation.endingBalanceChangedAmount;

  if (!showBanner) return null;

  const diff = (Number(baReconciliation.endingBalanceChangedAmount)
    - Number(baReconciliation.endingBalance)).toFixed(2);

  return (
    <div className={ styles.container }>
      <WarningTriangleIcon fontSize={ 20 } />
      <span>
        {`Your ending balance is off by $${diff}. At least one transaction was changed or
        deleted after this account was last reconciled.`}
      </span>
      <Button disabled={ isLoading } variant="primary" onClick={ handleClick }>
        { isLoading ? <SpinnerIcon spin /> : 'un-reconcile' }
      </Button>
    </div>
  );
};

export default EndingBalanceChangedBanner;
