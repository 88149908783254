import React from 'react';

import { useBankAccountReconciliationContext } from '@src/hooks/contexts/bank_account_reconciliation_context';
import { useBusinessContext } from '@src/hooks/contexts/business_context';
import { IBankAccountReconciliationItemTypes } from '@src/types/bank_account_reconciliations';

import Table from '@src/components/ui_v2/table';
import { EqualSignCircle } from '@src/components/utils/icomoon';
import GreenPlus from '@src/components/utils/icomoon/green_plus';
import RedMinus from '@src/components/utils/icomoon/red_minus';

import DownloadIcon from '../download_action/download_icon';
import { amountFormatter } from '../utils';

import styles from '@src/components/reconciliation_center/month_end/styles.module.scss';

const UnclearedBalanceDetail: React.FC<{
  items: IBankAccountReconciliationItemTypes }> =
  ({ items }) => {
    const baReconciliation = useBankAccountReconciliationContext();
    const business = useBusinessContext();

    const {
      excludedTransactions: { amount: excludedTransactionsAmount = '0' },
      notPushedTransactions: { amount: notPushedTransactionsAmount = '0' },
      additionalUnclearedBalance: { amount: additionalUnclearedBalanceAmount = '0' },
      pushedByOutsideTransactions: { amount: pushedByOutsideTransactionsAmount = '0' },
    } = items;

    const adjustedExcludedTransactionsAmount = parseFloat(excludedTransactionsAmount);
    const adjustedNotPushedTransactionsAmount = parseFloat(notPushedTransactionsAmount);

    const pushedByOutsideParams = {
      reconciliation_id: baReconciliation.id,
      business_id:       business.id,
      item_id:           items.pushedByOutsideTransactions?.id,
    };

    const excludedTransactionsParams = {
      reconciliation_id: baReconciliation.id,
      business_id:       business.id,
      filter:            { reconciliation_item_id: items.excludedTransactions?.id },
    };

    const notPushedTransactionsParams = {
      reconciliation_id: baReconciliation.id,
      business_id:       business.id,
      filter:            { reconciliation_item_id: items.notPushedTransactions?.id },
    };

    return (
      <Table.Row className={ styles['row-second-level'] }>
        <Table.TextCell>This Month</Table.TextCell>
        <Table.TextCell hideValueTooltip>
          <div className={ styles['cell-stats'] }>
            <span />
            <span>
              { amountFormatter(additionalUnclearedBalanceAmount) }
            </span>
          </div>
        </Table.TextCell>
        <Table.IconCell>
          <EqualSignCircle fontSize={ 24 } />
        </Table.IconCell>
        <td colSpan={ 2 }>
          <div className={ styles['additional-uncleared'] }>
            <div className={ styles['additional-uncleared-item'] }>
              <span className={ styles['additional-uncleared-item-name'] }>
                <GreenPlus fontSize={ 16 } />
                <span>Txns pushed to GL outside Docyt</span>
              </span>
              <div>
                <span style={ { marginRight: '5px' } }>{ amountFormatter(pushedByOutsideTransactionsAmount)}</span>
                <DownloadIcon exportParams={ pushedByOutsideParams } type="transactions_pushed_by_outside" />
              </div>
            </div>
            <div className={ styles['additional-uncleared-item'] }>
              <span className={ styles['additional-uncleared-item-name'] }>
                <RedMinus fontSize={ 16 } />
                <span>Excluded Transactions</span>
              </span>
              <div>
                <span style={ { marginRight: '5px' } }>{ amountFormatter(adjustedExcludedTransactionsAmount)}</span>
                <DownloadIcon exportParams={ excludedTransactionsParams } type="excluded_transactions" />
              </div>
            </div>
            <div className={ styles['additional-uncleared-item'] }>
              <span className={ styles['additional-uncleared-item-name'] }>
                <RedMinus fontSize={ 16 } />
                <span>Txns not pushed to the ledger</span>
              </span>
              <div>
                <span style={ { marginRight: '5px' } }>{ amountFormatter(adjustedNotPushedTransactionsAmount)}</span>
                <DownloadIcon exportParams={ notPushedTransactionsParams } type="not_pushed_transactions" />
              </div>
            </div>
          </div>
        </td>
      </Table.Row>
    );
  };

export default UnclearedBalanceDetail;
