// Have an optional parameter if the view part is need to be catered in future scope
const filterMapper = (view?: string) => [
  {
    heading:    'Expense',
    headingKey: 'expense',
    child:      [
      {
        heading:    'Bill Pay',
        headingKey: 'billPay',
        elements:   [
          {
            title: 'Uploaded Invoices',
            description:
                'Quick link to view invoices that have been uploaded but not verified',
            name:     'uploadedInvoices',
            disabled: !(view === 'accountant' || view === 'businessOwner'),
          },
          {
            title:       'Approved Invoices',
            description: 'Quick link to view invoices that need approval',
            name:        'approvedInvoices',
            disabled:    !(view === 'accountant' || view === 'businessOwner'),
          },
          {
            title: 'Ready to Pay Invoices',
            description:
                'Quick link to view unpaid invoices that are ready to pay',
            name:     'readyToPayInvoices',
            disabled: !(view === 'accountant' || view === 'businessOwner'),
          },
        ],
      },
      {
        heading:    'Corporate Cards',
        headingKey: 'corporateCards',
        elements:   [
          {
            title: 'Unapproved Receipts',
            description:
                'Quick link to view accounts receivable outstanding...',
            name:     'unapprovedReceipts',
            disabled: false,
          },
        ],
      },
      {
        heading:    'Expense Reports',
        headingKey: 'expenseReports',
        elements:   [
          {
            title:       'Employee Reimbursements',
            description: 'Quick link to view...',
            name:        'employeeReimbursements',
            disabled:    !(view === 'accountant' || view === 'businessOwner'),
          },
          {
            title:       'My Reimbursements',
            description: 'Quick link to view...',
            name:        'myReimbursements',
            disabled:    !(view === 'businessOwner'),
          },
        ],
      },
    ],
  },
  {
    heading:    'Revenue',
    headingKey: 'revenue',
    child:      [
      {
        heading:    'Undeposited Revenue',
        headingKey: 'undepositedRevenue',
        elements:   [
          {
            title:       'Undeposited Revenue Amount',
            description: 'Quick link to view',
            name:        'undepositedRevenueAmount',
            disabled:    !(view === 'accountant' || view === 'businessOwner'),
          },
        ],
      },
      {
        heading:    'Pending Review',
        headingKey: 'pendingReview',
        elements:   [
          {
            title: 'Missing Revenue Reports',
            description:
                'This will be a count of the number of days that revenue hasn’t arrived for.',
            name:     'missingRevenueReports',
            disabled: !(view === 'accountant' || view === 'businessOwner'),
          },
          {
            title:       'Unbalanced Reports',
            description: 'Quick link to view',
            name:        'unbalancedReports',
            disabled:    !(view === 'accountant' || view === 'businessOwner'),
          },
        ],
      },
    ],
  },
  {
    heading:    'Reconciliation',
    headingKey: 'reconciliation',
    child:      [
      {
        heading:    '',
        headingKey: '',
        elements:   [
          {
            title:       'Uncategorized Transactions',
            description: 'Quick link to view',
            name:        'uncategorizedTransactions',
            disabled:    !(view === 'accountant'),
          },
          {
            title:       'Uncleared Documents',
            description: 'Quick link to view',
            name:        'unclearedDocuments',
            disabled:    !(view === 'accountant'),
          },
          {
            title:       'Missing Bank Statements',
            description: 'Quick link to view',
            name:        'missingBankStatements',
            disabled:    !(view === 'accountant'),
          },
          {
            title:       'Closing Status',
            description: 'Quick link to view',
            name:        'closingStatus',
            disabled:    !(view === 'accountant'),
          },
        ],
      },
    ],
  },
  {
    heading:    'Collaboration',
    headingKey: 'collaboration',
    child:      [
      {
        heading:    'Flagged Items',
        headingKey: 'flaggedItems',
        elements:   [
          {
            title:       'Flagged Invoices',
            description: 'Quick link to view',
            name:        'flaggedInvoices',
            disabled:    false,
          },
          {
            title:       'Flagged Receipts',
            description: 'Quick link to view',
            name:        'flaggedReceipts',
            disabled:    false,
          },
          {
            title:       'Flagged Transactions',
            description: 'Quick link to view',
            name:        'flaggedTransactions',
            disabled:    false,
          },
        ],
      },
      {
        heading:    'Document Requests',
        headingKey: 'documentRequests',
        elements:   [
          {
            title:       'Reviewed',
            description: 'Quick link to view',
            name:        'reviewed',
            disabled:    false,
          },
          {
            title:       'Open',
            description: 'Quick link to view',
            name:        'open',
            disabled:    false,
          },
        ],
      },
      {
        heading:    'Unread Messages',
        headingKey: 'unreadMessages',
        elements:   [
          {
            title:       'Unread Messages Count',
            description: 'Quick link to view',
            name:        'unreadMessagesCount',
            disabled:    false,
          },
        ],
      },
    ],
  },
  {
    heading:    'Mailroom',
    headingKey: 'mailroom',
    child:      [
      {
        heading:    '',
        headingKey: '',
        elements:   [
          {
            title:       'Unreviewed Documents',
            description: 'Quick link to view',
            name:        'unreviewedDocuments',
            disabled:    !(view === 'accountant'),
          },
        ],
      },
    ],
  },
];

const expenseKeyToStringMap = {
  billPay:        'Bill Pay',
  corporateCards: 'Corporate Cards',
  expenseReports: 'Expense Reports',
};

const revenueKeyToStringMap = {
  undepositedRevenue: 'Undeposited Revenue',
  pendingReview:      'Pending Review',
};

const reconciliationKeyToStringMap = {
  uncategorizedTransactions: 'Uncategorized Transactions',
  unclearedDocuments:        'Uncleared Documents',
  missingBankStatements:     'Missing Bank Statements',
  closingStatus:             'Closing Status',
};

const collaborationKeyToStringMap = {
  flaggedItems:     'Flagged Items',
  documentRequests: 'Document Requests',
  unreadMessages:   'Unread Messages',
};

const billPayColors: ColorMapping = {
  uploadedInvoices:   '#8a2be2',
  approvedInvoices:   '#00bfff',
  readyToPayInvoices: '#20b2aa',
};

interface ColorMapping {
  [key: string]: string;
}

const flaggedItemsColors: ColorMapping = {
  flaggedInvoices:     '#8a2be2',
  flaggedReceipts:     '#00bfff',
  flaggedTransactions: '#20b2aa',
};

const documentRequestsColors: ColorMapping = {
  open:     '#FFB648',
  reviewed: '#00B887',
};

const pieChartConstants = {
  startAngle:  90,
  endAngle:    452,
  innerRadius: 12,
  outerRadius: 15,
};

const pieChartColors = ['#0088FE', '#00C49F'];
const defaultView = 'business_owner';
export {
  filterMapper,
  flaggedItemsColors,
  documentRequestsColors,
  expenseKeyToStringMap,
  revenueKeyToStringMap,
  reconciliationKeyToStringMap,
  collaborationKeyToStringMap,
  billPayColors,
  pieChartConstants,
  pieChartColors,
  defaultView,
};
