/* eslint-disable max-len */
import React, { FC, useCallback } from 'react';

import Big from 'big.js';

import { useBankStatementContext } from '@src/hooks/contexts/bank_statement_context';
import { useBusinessContext } from '@src/hooks/contexts/business_context';
import { useLocalStorage } from '@src/hooks/storage';
import { IBankAccountReconciliationItem, IBankAccountReconciliationItemTypes } from '@src/types/bank_account_reconciliations';

import Drawers from '@src/components/reconciliation_center/month_end/drawers';
import { amountFormatterX } from '@src/components/reconciliation_center/month_end/utils';
import Table from '@src/components/ui_v2/table';
import Tooltip from '@src/components/ui_v2/tooltip';
import { DocytSupportIcon } from '@src/components/utils/icomoon';

import styles from '../styles.module.scss';

interface PushedByDocytTransactionsProps {
  item: IBankAccountReconciliationItem
  items: IBankAccountReconciliationItemTypes
}

const PushedByDocytTransactions: FC<PushedByDocytTransactionsProps> = ({ item, items }) => {
  const bankStatement = useBankStatementContext();
  const hasTransactionMissingInGL = item.details?.missingInQboCount && item.details.missingInQboCount > 0;
  const isBankStatementVerified = window.Docyt.Common.Constants.BANK_STATEMENT_STATES.VERIFIED === bankStatement?.state;

  const debits = Big(bankStatement?.debits || 0);
  const credits = Big(bankStatement?.credits || 0);
  const amount = Big(item.amount || 0);

  const { id } = useBusinessContext();
  const breakup = useLocalStorage(`$pushed_by_docyt_breakup_toggle_${id}`, true);
  const breakupValue = breakup.storedValue;
  const handleBreakup = useCallback(() => {
    breakup.setValue(!breakupValue);
  }, [breakup, breakupValue]);

  let textColor = '#000000';
  if (isBankStatementVerified && !hasTransactionMissingInGL && amount.eq(debits.add(credits))) {
    textColor = '#02b07d';
  } else if (hasTransactionMissingInGL) {
    textColor = '#ff4040';
  }

  const tooltip = (
    <div className={ styles.tooltip }>
      <h4>This month</h4>
      Transactions of bank statement from this month that are found in GL with dates falling in this month
      <h4>Outside this month</h4>
      Transactions of bank statement from this month that are found in GL with dates falling before this month or after this month
      <h4>Missing in GL</h4>
      Transactions of bank statement from this month that have been pushed by Docyt into GL, but were found to be missing in GL (could be deleted from QBO due to some reason)
    </div>
  );

  const Breakup = (
    <>
      <Table.Row className={ styles['row-second-level'] }>
        <Table.TextCell>This Month</Table.TextCell>
        <Table.TextCell hideValueTooltip>
          <div className={ styles['cell-stats'] }>
            <span>{ item.details?.thisMonthCount || 0 }</span>
            <span>{ amountFormatterX(item.details?.thisMonthAmount) }</span>
          </div>
        </Table.TextCell>
      </Table.Row>
      <Table.Row className={ styles['row-second-level'] }>
        <Table.TextCell>Outside This Month</Table.TextCell>
        <Table.TextCell hideValueTooltip>
          <div className={ styles['cell-stats'] }>
            <span>{ item.details?.outsideThisMonthCount || 0 }</span>
            <span>{ amountFormatterX(item.details?.outsideThisMonthAmount) }</span>
          </div>
        </Table.TextCell>
      </Table.Row>
      <Table.Row className={ styles['row-second-level'] }>
        <Table.TextCell>Missing in GL</Table.TextCell>
        <Table.TextCell hideValueTooltip>
          <div className={ styles['cell-stats'] }>
            <span>{ item.details?.missingInQboCount || 0 }</span>
            <span>{ amountFormatterX(item.details?.missingInQboAmount) }</span>
          </div>
        </Table.TextCell>
      </Table.Row>
    </>
  );
  return (
    <>
      <Table.Row className={ styles.row }>
        <Table.TextCell
          hideValueTooltip
          className={ breakupValue ? styles['row-arrow-down'] : styles['row-arrow-right'] }
          onClick={ handleBreakup }
        >
          Bank Feed Transactions pushed to GL by Docyt
          <Tooltip.Hover as="span" content={ tooltip }>
            <DocytSupportIcon className={ styles['step-row-icon'] } />
          </Tooltip.Hover>
        </Table.TextCell>
        <Table.TextCell hideValueTooltip style={ { color: textColor } }>
          <div className={ styles['cell-stats'] }>
            <span>{ item.count }</span>
            <span>{ amountFormatterX(item.amount) }</span>
          </div>
        </Table.TextCell>
        <Table.IconCell>
          <Drawers.PushedByDocytTransactions items={ items } />
        </Table.IconCell>
        <Table.DateCell date={ item.acknowledgedOn } emptyValue="—" />
        <Table.UserCell emptyValue="—" user={ item.acknowledgedBy } />
      </Table.Row>
      { breakupValue && Breakup }
    </>
  );
};

export default PushedByDocytTransactions;
