/* eslint-disable max-len */
import React, { FC } from 'react';

import moment from 'moment';

import toastr from '@lib/toastr';
import { useBankStatementContext } from '@src/hooks/contexts/bank_statement_context';
import { useGetBankAccountReconciliationPDF } from '@src/hooks/queries/bank_account_reconciliations';
import { useDownloadDocument } from '@src/hooks/queries/documents';
import { IDownloadDocumentParams, IDownloadDocumentResponse } from '@src/requests/documents';
import { IBankAccountReconciliationWithLoading } from '@src/types/bank_account_reconciliations';
import { createAndDownloadBlobFile } from '@src/utils/download_file';

import { getReconciliationDateRange } from '@src/components/reconciliation_center/month_end/utils';
import ActionsDropdown from '@src/components/ui_v2/actions_dropdown';
import MutationStatus from '@src/components/utils/mutation_status';

interface DownloadButtonProps {
  reconciliation: IBankAccountReconciliationWithLoading
}

const DownloadButton: FC<DownloadButtonProps> = ({ reconciliation }) => {
  const bankStatement = useBankStatementContext();
  const [startDate] = getReconciliationDateRange(reconciliation, bankStatement);

  const downloadFromGeneration = useGetBankAccountReconciliationPDF();
  const downloadFromS3 = useDownloadDocument();
  const mutation = reconciliation.documentId ? downloadFromS3 : downloadFromGeneration;

  const downloadFile = (data: IDownloadDocumentResponse | Blob) => {
    const filename = data instanceof Blob ? `${moment(startDate).format('MMMYY')}_Reconciliation_Report.pdf` : data.fileName;
    createAndDownloadBlobFile(data, filename);
  };

  const data = {
    id:         reconciliation.id,
    documentId: reconciliation.documentId,
    type:       'original',
  };

  const options = {
    onSuccess: downloadFile,
  };

  const download = () => {
    try {
      if (reconciliation.documentId) {
        downloadFromS3.mutate(data as IDownloadDocumentParams, options);
      } else {
        downloadFromGeneration.mutate(data, options);
      }
    } catch (error) {
      const errorMessage = (error as Error).message || 'Failed to download PDF.';
      toastr.error(errorMessage, 'Error');
    }
  };

  return (
    <>
      <MutationStatus mutation={ mutation } />
      <ActionsDropdown.Action onSelect={ download }>
        Download Report
      </ActionsDropdown.Action>
    </>
  );
};

export default DownloadButton;
