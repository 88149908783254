import React from 'react';

import classNames from 'classnames';

import { IManagementGroup } from '@src/types/management_groups';

import DeleteManagementGroupAction from '@src/components/management_groups/actions/delete_management_group_action';
import EditManagementGroupAction from '@src/components/management_groups/actions/edit_management_group_action';
import ActionsDropdown from '@src/components/ui/actions_dropdown/actions_dropdown';
import Table from '@src/components/ui/table';
import Tooltip from '@src/components/ui/tooltip';

interface IManagementGroupsTableItemProps {
  managementGroup: IManagementGroup,
}

const ManagementGroupsTableItem = ({
  managementGroup,
}: IManagementGroupsTableItemProps) => {
  const businessNames = managementGroup.businesses.map((u) => u.displayName);
  let tooltipStr = businessNames.join(', ') || '0 business';
  if (businessNames.length > 3) {
    tooltipStr = `${businessNames.slice(0, 3).join(', ')}, +${businessNames.length - 3} more`;
  }

  const classes = classNames({
    'in-grey-800': !managementGroup.intercompanyEnabled,
  });

  return (
    <Table.Row>
      <Table.Cell>
        { managementGroup.name }
      </Table.Cell>
      <Table.Cell>
        <Tooltip
          style={ { color: 'black' } }
          title={ businessNames.join(', ') }
        >
          { tooltipStr }
        </Tooltip>
      </Table.Cell>
      <Table.Cell>
        <span className={ classes }>
          { managementGroup.intercompanyEnabled ? 'Enabled' : 'Disabled' }
        </span>
      </Table.Cell>
      <Table.Cell className="transaction-actions-column">
        <ActionsDropdown widthPercent={ 20 }>
          <EditManagementGroupAction managementGroup={ managementGroup } />
          <DeleteManagementGroupAction managementGroup={ managementGroup } />
        </ActionsDropdown>
      </Table.Cell>
    </Table.Row>
  );
};

export default React.memo(ManagementGroupsTableItem);
